'use strict';

const addCalendarsQueries = require('../../queries/configs/Calendars');
const { getMonthEdgeDates } = require('../../utils');

function Calendars({ client, fspId }) {

  const CalendarsOperations = {
    returnModel: {
      name: 'calendar',
    },
    baseUrl: `/fsps/${fspId}/calendars`,

    inRange: function (fromDate, toDate, cb) {
      return this.query(_ => [ _.dateRange(fromDate, toDate) ], cb);
    },

    getMonth: function (month, year, cb) {
      const [firstDay, lastDay] = getMonthEdgeDates(month, year);
      return this.inRange(firstDay, lastDay, cb);
    },

  };

  const CalendarsCreator = function Calendar(aDateString) {
    return {
      get: async function (cb) {
        const dates = await client
          .fsps(fspId)
          .calendars
          .query(_ => [ _.date(aDateString) ], cb);

        return dates[0];
      },
    };
  };

  return Object.assign(CalendarsCreator, CalendarsOperations);
}

module.exports = addCalendarsQueries(Calendars);
