const { inferCallbackArgument } = require('../utils');

function addGetOperation(client, pluralResource, resourceCreator) {
  return function () {
    const resource = new resourceCreator(...arguments);
    const id = arguments[0];

    const { baseUrl, returnModel } = pluralResource;

    resource.get = function () {
      const { restOfArguments, cb } = inferCallbackArgument(arguments);
      const [ queryParams ] = restOfArguments || [];
      return executeGet(client, baseUrl, id, returnModel, queryParams, cb);
    };

    return resource;
  };
}

function executeGet(client, baseUrl, id, returnModel, queryParams, cb) {
  return client.executeRequest('GET', `${baseUrl}/${id}`, {
    cb,
    queryParams,
    returnModel,
  });
}

module.exports = {
  addGetOperation,
};
