const addTopLevelFunctions = require('../resources/TopLevel');
const utils = require('../utils');

const createConfig = config => Object.assign(
  {},
  {
    timeout: 28000,
    logger: utils.noLogger,
  },
  config,
);

const completeClass = c => c.prototype = {

  _setKey: function (key, value) {
    this._config[key] = value;
  },

  getKey: function (key) {
    return this._config[key];
  },

  _addResources: function (resources) {
    for (let resourceKey in resources) {
      const resource = resources[resourceKey]({ client: this });
      this[resourceKey] = resource;
    }

    addTopLevelFunctions(this);
  },

};

module.exports = {
  completeClass,
  createConfig,
};