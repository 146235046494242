'use strict';

function UserAutopays({ client, autopayId, baseUrl }) {

  return {
    returnModel: {
      name: 'userautopay',
    },
    baseUrl: `${baseUrl}/${autopayId}/userautopays`,

    create: function create(data, queryParams, cb) {

      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },

  };
}

module.exports = UserAutopays;
