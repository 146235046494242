'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  payeeId,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/payeegroups`,
  returnModel: {
    name: 'payeegroup',
  },
  finders: [
    id,
    payerId,
    payeeId,
  ],
  orderBy: [
    id,
    payerId,
    payeeId,
  ],
});
