'use strict';

const { internalTransfer } = require('../../../datatypes');
const { appendFilter } = require('../../../queries');
const addInternalTransfersQueries = require('../../../queries/configs/InternalTransfers');

function InternalTransfers({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'internaltransfer',
      model: internalTransfer,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/internaltransfers`,
    queriesFilter: appendFilter('payerId', payerId),

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addInternalTransfersQueries(InternalTransfers);
