'use strict';
const { createAddQueries } = require('../addQueries');
const { createFilterConfig } = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'calendar',
  },
  finders: [
    createFilterConfig('date', 'date', 'date'),
    createFilterConfig('holidayOnly', 'holidayonly', 'string'),
  ],
  orderBy: [
    createFilterConfig('date', 'date'),
  ],
});
