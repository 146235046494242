'use strict';
const { createAddQueries } = require('../addQueries');
const { transactionHistory } = require('../../datatypes');
const {
  id,
  fspId: fspIdConfig,
  payerId,
  userId,
  modelId,
  payeeId,
  billerId,
  ddaId,
  externalDdaId,
  recipientId,
  amount,
  updatedAt,
  createdAt,
  transaction: {
    transactionType,
    sendOn,
    deliver,
    deliverOn,
    status,
    foreverOffOn,
    frequency,
    confirmationNumber,
    ipv4,
  },
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/histtrans`,
  returnModel: {
    name: 'histtran',
    model: transactionHistory,
  },
  finders: [
    id,
    fspIdConfig,
    payerId,
    userId,
    modelId,
    payeeId,
    billerId,
    ddaId,
    externalDdaId,
    recipientId,
    status,
    transactionType,
    sendOn,
    deliver,
    deliverOn,
    amount,
    confirmationNumber,
    ipv4,
    updatedAt,
    createdAt,
    createFilterConfig('schedId', 'schedid', 'id'),
    createFilterConfig('respCodeIdStr', 'respcodeidstr', 'string'),
    createFilterConfig('autopayOffOn', 'autopayoffon', 'string'),
    createFilterConfig('billerAccountNumberMask', 'billeraccountnumbermask', 'string'),
    createFilterConfig('payeeName', 'payeename', 'string'),
    createFilterConfig('payeeUspsStandardAddress', 'payeeuspsstandardaddress', 'string'),
    createFilterConfig('checkNumber', 'checknumber', 'string'),
    createFilterConfig('checkMemo', 'checkmemo', 'string'),
    createFilterConfig('description', 'description', 'string'),
    createFilterConfig('ddaAccountNbrMask', 'ddaaccountnbrmask', 'string'),
    createFilterConfig('ddaAccountSuffix', 'ddaaccountsuffix', 'string'),
    createFilterConfig('ddaMicrRtn', 'ddamicrrtn', 'string'),
    createFilterConfig('ddaFractRtn', 'ddafractrtn', 'string'),
    createFilterConfig('ddaAchRtn', 'ddaachrtn', 'string'),
    createFilterConfig('ddaWireRtn', 'ddawirertn', 'string'),
    createFilterConfig('extDdaAccountNbrMask', 'extddaaccountnbrmask', 'string'),
    createFilterConfig('extDdaAchRtn', 'extddaachrtn', 'amount'),
    createFilterConfig('extDdaWireRtn', 'extddawirertn', 'amount'),
    createFilterConfig('p2pDirectOffOn', 'p2pdirectoffon', 'string'),
    createFilterConfig('payerType', 'payertype', 'string'),
    createFilterConfig('payerName', 'payername', 'string'),
    createFilterConfig('payerCheckName', 'payercheckname', 'string'),
    createFilterConfig('payerPhone', 'payerphone', 'string'),
    createFilterConfig('recipientName', 'recipientname', 'string'),
    createFilterConfig('recipientEmail', 'recipientemail', 'string'),
    createFilterConfig('recipientMobilePhone', 'recipientmobilephone', 'string'),
    frequency,
    foreverOffOn,
    createFilterConfig('scheduleSendOn', 'schedulesendon', 'date'),
    createFilterConfig('scheduleDeliverOn', 'scheduledeliveron', 'date'),
    createFilterConfig('autoPayRuleId', 'autopayruleid', 'id'),
    createFilterConfig('sendOrDeliver', 'sendordeliver', 'string'),
    createFilterConfig('feeAmount', 'feeamount', 'amount'),
    createFilterConfig('windowId', 'windowid', 'id'),
    createFilterConfig('processOn', 'processon', 'string'),
    createFilterConfig('fundingAccount', 'fundingaccount', 'string'),
    createFilterConfig('fundingMask', 'fundingmask', 'string'),
    createFilterConfig('destination', 'destination', 'string'),
    createFilterConfig('destinationMask', 'destinationmask', 'string'),
    createFilterConfig('insUserId', 'insuserid', 'id'),
    createFilterConfig('updUserId', 'upduserid', 'id'),
    createFilterConfig('ssoTrans', 'ssotrans', 'string'),
    createFilterConfig('intelligentMailBarcode', 'intelligentmailbarcode', 'string'),
  ],
  orderBy: [
    id,
    status,
    transactionType,
    sendOn,
    deliver,
    deliverOn,
    amount,
    payerId,
    modelId,
    payeeId,
    ddaId,
    externalDdaId,
    recipientId,
    frequency,
    foreverOffOn,
    confirmationNumber,
    ipv4,
    updatedAt,
    createdAt,
    createFilterConfig('fundingAccount', 'fundingaccount'),
    createFilterConfig('fundingMask', 'fundingmask'),
    createFilterConfig('destination', 'destination'),
    createFilterConfig('destinationMask', 'destinationmask'),
  ],
});
