'use strict';

const { addGetOperation } = require('../../../queries');
const addTransactionHistoryQueries = require('../../../queries/configs/TransactionHistory');
const { transactionHistory } = require('../../../datatypes');

const TransactionHistoryResearchRequests = require('./TransactionHistoryResearchRequests');

function TransactionHistory({ client, fspId }) {

  const TransactionHistoryOperations = {
    returnModel: {
      name: 'histtran',
      model: transactionHistory,
    },
    baseUrl: `/fsps/${fspId}/histtrans`,

  };

  const TransactionHistoryCreator = addGetOperation(client, TransactionHistoryOperations,
    function (transactionHistoryId) {
      return {
        transactionHistoryResearchRequests: new TransactionHistoryResearchRequests({ client, fspId, transactionHistoryId }),
        decryptFundingAccountNumber: decryptAccountNumber(client, historicFundingDecryptFunctions),
        decryptDestinationAccountNumber: decryptAccountNumber(client, historicDestinationDecryptFunctions),
        updateReturnedCheck: function (queryParams, cb) {
          return client.executeRequest('POST', `${TransactionHistoryOperations.baseUrl}/${transactionHistoryId}/returnedchecks`, {
            queryParams,
            cb,
            returnModel: TransactionHistoryOperations.returnModel,
          });
        },
        updateReturnedA2aNOCs: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${TransactionHistoryOperations.baseUrl}/${transactionHistoryId}/returneda2anocs`, {
            data,
            queryParams,
            cb,
            returnModel: TransactionHistoryOperations.returnModel,
          });
        },
        updateReturnedA2as: function create(data, queryParams, cb) {
          return client.executeRequest('POST', `${TransactionHistoryOperations.baseUrl}/${transactionHistoryId}/returneda2as`, {
            data,
            queryParams,
            cb,
            returnModel: TransactionHistoryOperations.returnModel,
          });
        },
        updateReturnedStatus: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${TransactionHistoryOperations.baseUrl}/${transactionHistoryId}/returnedtrans`, {
            data,
            queryParams,
            cb,
            returnModel: TransactionHistoryOperations.returnModel,
          });
        },
      };
    },
  );

  return Object.assign(TransactionHistoryCreator, TransactionHistoryOperations);
}

const decryptHistory = (accountFieldName) => async (client, transaction, cb) => {
  const decryptedTransactionHistory = await client.fsps(transaction.fspId).transactionHistory.query(_ => [
    _.id(transaction.id),
    _.attributes([ accountFieldName ]),
  ], cb);
  return decryptedTransactionHistory[0][accountFieldName];
}

const historicFundingDecryptFunctions = {
  a2ain: decryptHistory('extDdaAccountNbr'),
  a2anew: decryptHistory('extDdaAccountNbr'),
  a2aout: decryptHistory('ddaAccountNbr'),
  a2axfer: decryptHistory('ddaAccountNbr'),
  p2p: decryptHistory('ddaAccountNbr'),
  bp: decryptHistory('ddaAccountNbr'),
};

const historicDestinationDecryptFunctions = {
  a2ain: decryptHistory('ddaAccountNbr'),
  a2anew: decryptHistory('ddaAccountNbr'),
  a2aout: decryptHistory('extDdaAccountNbr'),
  a2axfer: decryptHistory('transferToDdaAccountNbr'),
  p2p: async () => { throw new Error('nUnsupported operationo') },
  bp: decryptHistory('billerAccountNumber'),
};

const decryptAccountNumber = (client, decryptFunctions) => (transaction, cb) => {
  const noAccountNumber = async () => undefined;
  const decrypt = decryptFunctions[transaction.transactionType.toLowerCase()] || noAccountNumber;
  return decrypt(client, transaction, cb);
}

module.exports = addTransactionHistoryQueries(TransactionHistory);
