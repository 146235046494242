'use strict';

const { addGetOperation } = require('../../queries');
const addVenmoContactsQueries = require('../../queries/configs/VenmoContacts');

function VenmoContacts({ client, fspId }) {

  const VenmoContactsOperations = {
    returnModel: {
      name: 'recipient',
    },
    baseUrl: `/fsps/${fspId}/venmocontacts`,

  };

  const VenmoContactsCreator = addGetOperation(client, VenmoContactsOperations, function VenmoContact(venmoContactId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${VenmoContactsOperations.baseUrl}/${venmoContactId}`, {
          queryParams,
          cb,
          returnModel: VenmoContactsOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${VenmoContactsOperations.baseUrl}/${venmoContactId}`, {
          data,
          queryParams,
          cb,
          returnModel: VenmoContactsOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(VenmoContactsCreator, VenmoContactsOperations);
}

module.exports = addVenmoContactsQueries(VenmoContacts);
