'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'sessions',
  },
  finders: [
    id,
  ],
  orderBy: [
    id,
  ],
});
