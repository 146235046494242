'use strict';
const newApi = require('../api');
const BrowserAccessTokenProvider = require('../api/accessTokenProvider/BrowserAccessTokenProvider');
const NoAccessTokenProvider = require('../api/accessTokenProvider/NoAccessTokenProvider');
const { completeClass, createConfig } = require('./common');

function BrowserClient({
  resources,
  authHost,
  extraRequestHttpOptions,
  httpClient,
  noAccessToken,
  config = {},
}) {
  if (!(this instanceof BrowserClient)) {
    return new BrowserClient(authHost, extraRequestHttpOptions);
  }

  const AccessTokenProvider = noAccessToken ? NoAccessTokenProvider : BrowserAccessTokenProvider;
  const accessTokenProvider = new AccessTokenProvider(authHost, extraRequestHttpOptions);
  const api = newApi(accessTokenProvider, httpClient);

  this.executeRequest = async function () {
    return api.executeRequest(...arguments);
  };

  this.clearCache = () => {
    accessTokenProvider.clearCache();
  };

  this.getEnvironment = () => {
    return accessTokenProvider.environment;
  };

  this.addMetrics = async function (data, cb) {
    return api.executeRequest('POST', '/metrics', {
      httpOptions: {
        host: authHost,
      },
      data,
      cb,
    });
  };

  this.setExtraRequestHttpOptions = (httpOptions) => {
    accessTokenProvider.extraRequestHttpOptions = httpOptions;
  };

  this._config = createConfig(config);

  api.configure(this._config);

  this._addResources(resources);

}

completeClass(BrowserClient);

module.exports = (resources) => (options) => {
  return new BrowserClient({ resources, ...options });
};
