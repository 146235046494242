'use strict';

const { addGetOperation } = require('../../queries');

function RtnInfos({ client, fspId }) {

  const RtInfosOperations = {
    returnModel: {
      name: 'rtninfo',
    },
    baseUrl: `/fsps/${fspId}/rtninfo`,

  };

  const RtnInfosCreator = addGetOperation(client, RtInfosOperations, function RtInfo() {
    return {

    };
  });

  return Object.assign(RtnInfosCreator, RtInfosOperations);
}

module.exports = RtnInfos;
