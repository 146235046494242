'use strict';
const { createAddQueries } = require('../addQueries');
const { createFilterConfig } = require('./commons');
module.exports = createAddQueries({
  returnModel: {
    name: 'billeraliasname',
  },
  finders: [
    createFilterConfig('alias', 'alias', 'string'),
  ],
  orderBy: [
    'alias',
  ],
});
