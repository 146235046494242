'use strict';
const { addGetOperation } = require('./addGetOperation');

const appendFilter = (opName, value) =>
  (f) => (_) => [...f(_), _[opName](value)];

module.exports = {
  appendFilter,
  addGetOperation,
};
