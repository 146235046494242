'use strict';

const { addGetOperation } = require('../../queries');
const addInsightEventsQueries = require('../../queries/configs/InsightEvents');

function InsightEvents({ client, fspId }) {

  const InsightEventsOperations = {
    returnModel: {
      name: 'insightevents',
    },
    baseUrl: `/fsps/${fspId}/insightevents`,
  };

  const InsightEventsCreator = addGetOperation(client, InsightEventsOperations, function InsightEvent() {
    return {
    };
  });

  return Object.assign(InsightEventsCreator, InsightEventsOperations);
}

module.exports = addInsightEventsQueries(InsightEvents);
