'use strict';
const { addGetOperation } = require('../../queries');
const addUserDdasQueries = require('../../queries/configs/UserDdas');

function UserDdas({ client, fspId }) {

  const UserDdasOperations = {
    returnModel: {
      name: 'userdda',
    },
    baseUrl: `/fsps/${fspId}/userddas`,

  };

  const UserDdasCreator = addGetOperation(client, UserDdasOperations, function UserDda(userDdaId) {
    return {};
  });

  return Object.assign(UserDdasCreator, UserDdasOperations);
}

module.exports = addUserDdasQueries(UserDdas);
