'use strict';

const { modelP2pPayment } = require('../../../datatypes');
const { appendFilter } = require('../../../queries');
const addModelP2pPaymentsQueries = require('../../../queries/configs/ModelP2pPayments');

function ModelP2pPayments({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'modelp2ppayment',
      model: modelP2pPayment,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/modelp2ppayments`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addModelP2pPaymentsQueries(ModelP2pPayments);
