'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/userddas`,
  returnModel: {
    name: 'userdda',
  },
  finders: [
    id,
    userId,
    payerId,
  ],
  orderBy: [
    id,
    userId,
    payerId,
  ],
});
