const newPayrailzApi = require('./payrailz');


module.exports = (accessTokenProvider, httpClient) => {
  const payrailzApi = newPayrailzApi(accessTokenProvider, httpClient);
  return {
    payrailzApi,
    configure: (options) => {
      payrailzApi.configure(() => options);
    },
    newToken: (scope) => {
      return payrailzApi.newToken(scope);
    },
    executeRequest: async function (httpMethod, url, options) {
      return payrailzApi.executeRequest(httpMethod, url, options);
    },
  };
};
