'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  externalDdaId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'iavrequests',
  },
  finders: [
    id,
    payerId,
    userId,
    externalDdaId,
    createFilterConfig('requestId', 'requestid', 'id'),
    createFilterConfig('iavStatus', 'iavstatus', 'string'),
    createFilterConfig('requestType', 'requesttype', 'string'),
  ],
  orderBy: [
    id,
    payerId,
    userId,
    externalDdaId,
    createFilterConfig('iavStatus', 'iavstatus', 'string'),
  ],
});
