'use strict';

const { addGetOperation } = require('../../queries');
const addModelA2aTransfersQueries = require('../../queries/configs/ModelA2aTransfers');
const { a2aTransfer, modelA2aTransfer } = require('../../datatypes');

function ModelA2aTransfers({ client, fspId }) {

  const ModelA2aTransfersOperations = {
    returnModel: {
      name: 'modela2atransfer',
      model: modelA2aTransfer,
    },
    baseUrl: `/fsps/${fspId}/modela2atransfers`,

  };

  const ModelA2aTransfersCreator = addGetOperation(client, ModelA2aTransfersOperations, function A2aTransfers(a2aTransferId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${ModelA2aTransfersOperations.baseUrl}/${a2aTransferId}`, {
          queryParams,
          cb,
          returnModel: ModelA2aTransfersOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelA2aTransfersOperations.baseUrl}/${a2aTransferId}`, {
          data,
          queryParams,
          cb,
          returnModel: ModelA2aTransfersOperations.returnModel,
        });
      },
      toSingle: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelA2aTransfersOperations.baseUrl}/${a2aTransferId}/modeltosingle`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'a2atransfer',
            model: a2aTransfer,
          },
        });
      },
    };
  });

  return Object.assign(ModelA2aTransfersCreator, ModelA2aTransfersOperations);
}

module.exports = addModelA2aTransfersQueries(ModelA2aTransfers);
