'use strict';

function P2pPaymentLink({ client, fspId }) {

  const P2pPaymentLinkOperations = {
    baseUrl: `/fsps/${fspId}/p2ppaymentlink`,
  };

  const P2pPaymentLinkCreator = function P2pPayment(linkId) {
    return {
      validateSecurityQuestion: function updateStatus(securityAnswer, cb) {
        return client.executeRequest('POST',
          `${P2pPaymentLinkOperations.baseUrl}/${linkId}/recipientsecurityquestion`, {
          data: { securityAnswer },
          cb,
          returnModel: {
            name: 'securityquestion',
          },
        });
      },
    };
  };

  return Object.assign(P2pPaymentLinkCreator, P2pPaymentLinkOperations);
}

module.exports = (P2pPaymentLink);
