'use strict';

const { addGetOperation } = require('../../queries');
const addBillSavingOffersQueries = require('../../queries/configs/BillSavingOffers');

function BillSavingOffers({ client, fspId }) {

  const BillSavingOffersOperations = {
    returnModel: {
      name: 'billsavingoffer',
    },
    baseUrl: `/fsps/${fspId}/billsavingoffers`,
  };

  const BillSavingOffersCreator = addGetOperation(client, BillSavingOffersOperations, function BillSavingOffer(billSavingOfferId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${BillSavingOffersOperations.baseUrl}/${billSavingOfferId}`, {
          data,
          queryParams,
          cb,
          returnModel: BillSavingOffersOperations.returnModel,
        });
      },
      submit: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${BillSavingOffersOperations.baseUrl}/${billSavingOfferId}/submit`, {
          data,
          queryParams,
          cb,
          returnModel: BillSavingOffersOperations.returnModel,
        });
      },
      cancel: function (queryParams, cb) {
        return client.executeRequest('POST', `${BillSavingOffersOperations.baseUrl}/${billSavingOfferId}/cancel`, {
          queryParams,
          cb,
          returnModel: BillSavingOffersOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(BillSavingOffersCreator, BillSavingOffersOperations);
}

module.exports = addBillSavingOffersQueries(BillSavingOffers);
