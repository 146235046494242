'use strict';
const { mapObjIndexed } = require('ramda');
const { addGetOperation, appendFilter } = require('../../../queries');
const { decryptField } = require('../../../queries/utils');

const addA2aTransfersQueries = require('../../../queries/configs/A2aTransfers');
const addBillPaymentsQueries = require('../../../queries/configs/BillPayments');
const addDdasQueries = require('../../../queries/configs/Ddas');
const addExternalDdasQueries = require('../../../queries/configs/ExternalDdas');
const addIavRequestsQueries = require('../../../queries/configs/IavRequests');
const addInternalTransfersQueries = require('../../../queries/configs/InternalTransfers');
const addFspConfigsQueries = require('../../../queries/configs/FspConfigs');
const addModelA2aTransfersQueries = require('../../../queries/configs/ModelA2aTransfers');
const addModelBillPaymentsQueries = require('../../../queries/configs/ModelBillPayments');
const addModelInternalTransfersQueries = require('../../../queries/configs/ModelInternalTransfers');
const addModelP2pPaymentsQueries = require('../../../queries/configs/ModelP2pPayments');
const addRecipientsQueries = require('../../../queries/configs/Recipients');
const addScheduledTransactionsQueries = require('../../../queries/configs/ScheduledTransactions');
const addTransactionHistoryQueries = require('../../../queries/configs/TransactionHistory');
const addPayeesQueries = require('../../../queries/configs/Payees');
const addPayerEbillerNotifyQueries = require('../../../queries/configs/PayerEbillerNotify');
const addPayerPayeeGroupsQueries = require('../../../queries/configs/PayerPayeeGroups');
const addP2pPaymentsQueries = require('../../../queries/configs/P2pPayments');
const addUsersQueries = require('../../../queries/configs/Users');

function Users({ client, fspId }) {

  const UsersOperations = {
    returnModel: {
      name: 'user',
    },
    baseUrl: `/fsps/${fspId}/users`,

  };

  const UserCreator = addGetOperation(client, UsersOperations,
    function User(userId, { deletedOnly } = {}) {
      const createQueriesOnlyResource = (path, returnModelName) =>
        function UserQuery() {
          return {
            baseUrl: `${UsersOperations.baseUrl}/${userId}/${path}`,
            queriesFilter: appendFilter('userId', userId),
            returnModel: {
              name: returnModelName,
            },
          };
        };
      const buildResource = (queriesFunc, path, returnModelName) =>
        (args) => queriesFunc(createQueriesOnlyResource(path, returnModelName))(args);

      const resources = {
        a2aTransfers: buildResource(addA2aTransfersQueries, 'a2atransfers', 'a2atransfer'),
        billPayments: buildResource(addBillPaymentsQueries, 'billpayments', 'billpayment'),
        ddas: buildResource(addDdasQueries, 'ddas', 'dda'),
        externalDdas: buildResource(addExternalDdasQueries, 'externalddas', 'externaldda'),
        iavRequests: addIavRequestsQueries(function IavRequests() {
          return {
            baseUrl: `${UsersOperations.baseUrl}/${userId}/iavrequests`,
          }
        }),
        fspConfigs: buildResource(addFspConfigsQueries, 'fspconfigs', 'fspconfig'),
        internalTransfers: buildResource(addInternalTransfersQueries, 'internaltransfers', 'internaltransfer'),
        modelA2aTransfers: buildResource(addModelA2aTransfersQueries, 'modela2atransfers', 'modela2atransfer'),
        modelBillPayments: buildResource(addModelBillPaymentsQueries, 'modelbillpayments', 'modelbillpayment'),
        modelInternalTransfers: buildResource(addModelInternalTransfersQueries, 'modelinternaltransfers', 'modelinternaltransfer'),
        modelP2pPayments: buildResource(addModelP2pPaymentsQueries, 'modelp2ppayments', 'modelp2ppayment'),
        recipients: buildResource(addRecipientsQueries, 'recipients', 'recipient'),
        scheduledTransactions: buildResource(addScheduledTransactionsQueries, 'schedtrans', 'schedtran'),
        transactionHistory: buildResource(addTransactionHistoryQueries, 'histtrans', 'histtran'),
        transactionHistoryTrackings: require('./TransactionHistoryTrackings'),
        p2pPayments: buildResource(addP2pPaymentsQueries, 'p2ppayments', 'p2ppayment'),
        payees: buildResource(addPayeesQueries, 'payees', 'payee'),
        payerEbillerNotify: buildResource(addPayerEbillerNotifyQueries, 'payerebillernotify', 'payerebillernotify'),
        payerPayeeGroups: addPayerPayeeGroupsQueries(function PayerPayeeGroups() {
          return {
            baseUrl: `${UsersOperations.baseUrl}/${userId}/payerpayeegroups`,
          }
        }),
      };

      return Object.assign(
        mapObjIndexed((builder) => builder({ client, fspId, userId }), resources),
        {
          update: function (data, queryParams, cb) {
            return client.executeRequest('POST', `${UsersOperations.baseUrl}/${userId}`, {
              data,
              queryParams,
              cb,
              returnModel: UsersOperations.returnModel,
            });
          },
          defaultDdas: function (data, queryParams, cb) {
            return client.executeRequest('POST', `${UsersOperations.baseUrl}/${userId}/userdefaultddas`, {
              data,
              queryParams,
              cb,
              returnModel: { name: 'userdda' },
            });
          },
          decryptFspUserId: (cb) =>
            decryptField({
              client,
              id: userId,
              fieldName: 'fspUserId',
              resourceDefinition: UsersOperations,
              queryParams: {
                deletedOnly,
              },
              cb,
            }),
          otpInfo: function (queryParams, cb) {
            return client.executeRequest('GET', `${UsersOperations.baseUrl}/${userId}/otpinfo`, {
              queryParams,
              cb,
              returnModel: {
                name: 'userOtp',
              },
            });
          },
        },
      );
    });

  return Object.assign(UserCreator, UsersOperations);
}

module.exports = addUsersQueries(Users);
