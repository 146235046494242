'use strict';

const { addGetOperation } = require('../../queries');
const addModelInternalTransfersQueries = require('../../queries/configs/ModelInternalTransfers');
const { internalTransfer, modelInternalTransfer } = require('../../datatypes');

function ModelInternalTransfers({ client, baseUrl }) {

  const ModelInternalTransfersOperations = {
    returnModel: {
      name: 'modelinternaltransfer',
      model: modelInternalTransfer,
    },
    baseUrl: `${baseUrl}/modelinternaltransfers`,
  };

  const ModelInternalTransfersCreator = addGetOperation(client, ModelInternalTransfersOperations, function ModelInternalTransfer(modelInternalTransferId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${ModelInternalTransfersOperations.baseUrl}/${modelInternalTransferId}`, {
          queryParams,
          cb,
          returnModel: ModelInternalTransfersOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelInternalTransfersOperations.baseUrl}/${modelInternalTransferId}`, {
          data,
          queryParams,
          cb,
          returnModel: ModelInternalTransfersOperations.returnModel,
        });
      },
      toSingle: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelInternalTransfersOperations.baseUrl}/${modelInternalTransferId}/modeltosingle`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'internaltransfer',
            model: internalTransfer,
          },
        });
      },

    };
  });

  return Object.assign(ModelInternalTransfersCreator, ModelInternalTransfersOperations);
}

module.exports = addModelInternalTransfersQueries(ModelInternalTransfers);
