'use strict';

const addBillerAliasNamesQueries = require('../../queries/configs/BillerAliasNames');

function BillerAliasNames({ fspId }) {
  return {
    returnModel: {
      name: 'billeraliasnames',
    },
    baseUrl: `/fsps/${fspId}/billeraliasnames`,
  };
}

module.exports = addBillerAliasNamesQueries(BillerAliasNames);
