'use strict';

const { addGetOperation } = require('../../queries');
const addA2aTransfersQueries = require('../../queries/configs/A2aTransfers');
const { a2aTransfer, modelA2aTransfer } = require('../../datatypes');

function A2aTransfers({ client, fspId }) {

  const A2aTransfersOperations = {
    returnModel: {
      name: 'a2atransfer',
      model: a2aTransfer,
    },
    baseUrl: `/fsps/${fspId}/a2atransfers`,
  };

  const A2aTransfersCreator = addGetOperation(client, A2aTransfersOperations, function A2aTransfer(a2aTransferId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${A2aTransfersOperations.baseUrl}/${a2aTransferId}`, {
          queryParams,
          cb,
          returnModel: A2aTransfersOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${A2aTransfersOperations.baseUrl}/${a2aTransferId}`, {
          data,
          queryParams,
          cb,
          returnModel: A2aTransfersOperations.returnModel,
        });
      },
      toModel: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${A2aTransfersOperations.baseUrl}/${a2aTransferId}/singletomodel`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'modela2atransfer',
            model: modelA2aTransfer,
          },
        });
      },
    };
  });

  return Object.assign(A2aTransfersCreator, A2aTransfersOperations);
}

module.exports = addA2aTransfersQueries(A2aTransfers);
