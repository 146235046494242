const { recipient } = require('../../datatypes');

module.exports = function ({ client, baseUrl }) {
  return function (recipientId, queryParams, cb) {
    return client.executeRequest('POST', `${baseUrl}/resetrecipientpending/${recipientId}`, {
      cb,
      queryParams,
      returnModel: {
        name: 'recipient',
        model: recipient,
      },
    });
  };
}