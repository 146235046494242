'use strict';

const { addGetOperation } = require('../../queries');
const addPayeeGroupsQueries = require('../../queries/configs/PayeeGroups');

function PayeeGroups({ client, fspId }) {

  const PayeeGroupsOperations = {
    returnModel: {
      name: 'payeegroup',
    },
    baseUrl: `/fsps/${fspId}/payeegroups`,

  };

  const PayeeGroupsCreator = addGetOperation(client, PayeeGroupsOperations, function UserPayee(payeeGroupId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${PayeeGroupsOperations.baseUrl}/${payeeGroupId}`, {
          data,
          queryParams,
          cb,
          returnModel: PayeeGroupsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${PayeeGroupsOperations.baseUrl}/${payeeGroupId}`, {
          queryParams,
          cb,
          returnModel: PayeeGroupsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(PayeeGroupsCreator, PayeeGroupsOperations);
}

module.exports = addPayeeGroupsQueries(PayeeGroups);
