'use strict';

const { appendFilter } = require('../../../queries');
const addBillPaymentsQueries = require('../../../queries/configs/BillPayments');
const { billPayment } = require('../../../datatypes');

function BillPayments({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'billpayment',
      model: billPayment,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/billpayments`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addBillPaymentsQueries(BillPayments);
