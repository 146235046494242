'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId, transactionHistoryTrackingsId }) => `/fsps/${fspId}/histtrantrackings/${transactionHistoryTrackingsId}`,
  returnModel: {
    name: 'histtrantrackings',
  },
  finders: [
    id,
    ({
      name: 'fullAudit',
      type: 'fixedValue',
      queryParamName: 'histtrantracking',
      value: 'all',
    }),
  ],
  orderBy: [
  ],
});
