let httpClient;
try {
  const axios = require('axios');

  const axiosClient = async (options) => {
    const axiosResponse = await axios(options);
    return {
      status: axiosResponse.status,
      statusText: axiosResponse.statusText,
      method: axiosResponse.config.method,
      url: axiosResponse.config.url,
      headers: axiosResponse.headers,
      data: axiosResponse.data,
    };
  }

  httpClient = axiosClient;
}
// eslint-disable-next-line no-empty
catch (e) {
}

module.exports = httpClient;