'use strict';

const { addGetOperation } = require('../../../queries');
const addExternalDdasQueries = require('../../../queries/configs/ExternalDdas');
const { externalDda } = require('../../../datatypes');
const UserExternalDdas = require('./UserExternalDdas');
const { decryptField } = require('../../../queries/utils');

function ExternalDdas({ client, fspId }) {

  const ExternalDdasOperations = {
    returnModel: {
      name: 'externaldda',
      model: externalDda,
    },
    baseUrl: `/fsps/${fspId}/externalddas`,

  };

  const ExternalDdasCreator = addGetOperation(client, ExternalDdasOperations,
    function ExternalDda(externalDdaId, { deletedOnly } = {}) {
      return {
        userExternalDdas: new UserExternalDdas({ client, fspId, externalDdaId }),
        update: function(data, queryParams, cb) {
          return client.executeRequest('POST', `${ExternalDdasOperations.baseUrl}/${externalDdaId}`, {
            data,
            queryParams,
            cb,
            returnModel: ExternalDdasOperations.returnModel,
          });
        },
        updateStatus: function(data, queryParams, cb) {
          return this.update(data, queryParams, cb);
        },
        delete: function(queryParams, cb) {
          return client.executeRequest('DELETE', `${ExternalDdasOperations.baseUrl}/${externalDdaId}`, {
            queryParams,
            cb,
            returnModel: ExternalDdasOperations.returnModel,
          });
        },

        decryptAccountNumber: (cb) =>
          decryptField({
            client,
            id: externalDdaId,
            fieldName: 'accountNbr',
            resourceDefinition: ExternalDdasOperations,
            queryParams: {
              deletedOnly,
            },
            cb,
          }),

      };
    },
  );

  return Object.assign(ExternalDdasCreator, ExternalDdasOperations);
}

module.exports = addExternalDdasQueries(ExternalDdas);
