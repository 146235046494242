'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/ddas`,
  returnModel: {
    name: 'dda',
  },
  finders: [
    id,
    userId,
    payerId,
    createFilterConfig('fspDdaId', 'fspddaid', 'id'),
    createFilterConfig('fspDdaIdMask', 'fspddaidmask', 'string'),
    createFilterConfig('accountNbr', 'accountnbr', 'string'),
    createFilterConfig('accountSuffix', 'accountsuffix', 'string'),
  ],
  orderBy: [
    id,
    userId,
    payerId,
    createFilterConfig('fspDdaId', 'fspddaid'),
  ],
});
