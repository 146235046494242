'use strict';

const { addGetOperation } = require('../../../queries');
const addIavRequestsQueries = require('../../../queries/configs/IavRequests');

function IavRequests({ client, fspId, payerId }) {
  const baseUrl = `/fsps/${fspId}/payers/${payerId}/iavrequests`;
  const IavRequestsOperations = {
    returnModel: {
      name: 'iavrequests',
    },
    baseUrl,
    create: function (data, queryParams, cb) {
      return client.executeRequest('POST', baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: IavRequestsOperations.returnModel,
      });
    },
  };

  const IavRequestsCreator = addGetOperation(client, IavRequestsOperations, function IavRequest() {
    return {
    };
  });

  return Object.assign(IavRequestsCreator, IavRequestsOperations);
}

module.exports = addIavRequestsQueries(IavRequests);
