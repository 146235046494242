const { compose, prop, fromPairs, omit} = require('ramda');
const changeCaseObject = require('change-case-object');
const { capitalize, removeKey } = require('../utils');

const matchOffOnField = (name) => name.match(/^(.*)OffOn$/);
const isOffOnField = (name) => matchOffOnField(name) != null;

const convertBooleanName = (name) => {
  const groups = matchOffOnField(name);
  return groups !== null ? 'is' + capitalize(groups[1]) : name;
};

const convertBooleanValue = (value) => {
  const lowerCaseValue = value.toLowerCase();
  if(!['on', 'off'].includes(lowerCaseValue)) {
    return value;
  }
  return lowerCaseValue === 'on';
};

const booleanTransform = (object) => {
  const applyOnly =
    (predicate) =>
      (transform) =>
        (item, index) => predicate(item, index) ? transform(item, index) : item;

  const convertBooleanField =
    ([fieldName, value]) =>
      [convertBooleanName(fieldName), convertBooleanValue(value)];

  const isBooleanField = compose(isOffOnField, prop('0'));

  const pairs = Object.keys(object)
    .map( fieldName => [fieldName, object[fieldName]])
    .map(applyOnly(isBooleanField)(convertBooleanField));

  return fromPairs(pairs);
};

const transformJson = (jsonString) => {
  if(String(jsonString).trim() === '') {
    return null;
  }
  return changeCaseObject.camelCase(JSON.parse(jsonString));
};

const renameField = (oldFieldName, newFieldName) => (object) => {
  const newObject = Object.assign(
    {},
    object,
    { [newFieldName] : object[oldFieldName] },
  );

  return removeKey(newObject, oldFieldName);
};

const computeField = (fieldName, transformation) => (object) => {
  object[fieldName] = transformation(object);
  return object;
};

const lowerCaseField = (fieldName) => (object) => {
  return Object.assign(
    {},
    object,
    { [fieldName] : object[fieldName] && object[fieldName].toLowerCase() },
  );
}

const fieldToJson = (fieldName) => (object) => Object.assign(
  {},
  object,
  { [fieldName] : object[fieldName] && transformJson(object[fieldName]) },
);

const blacklistFields = (fieldNames) => (object) => omit(fieldNames, object);

module.exports = {
  blacklistFields,
  booleanTransform,
  computeField,
  convertBooleanName,
  fieldToJson,
  lowerCaseField,
  renameField,
  transformJson,
};
