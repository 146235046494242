'use strict';

const { addGetOperation } = require('../../queries');
const addPayeeEbillsQueries = require('../../queries/configs/PayeeEbills');

function PayeeEbills({ client, fspId }) {

  const PayeeEbillsOperations = {
    returnModel: {
      name: 'payeeebill',
    },
    baseUrl: `/fsps/${fspId}/payeeebills`,

  };

  const PayeeEbillsCreator = addGetOperation(client, PayeeEbillsOperations, function UserPayee(payeeEbillId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${PayeeEbillsOperations.baseUrl}/${payeeEbillId}`, {
          data,
          queryParams,
          cb,
          returnModel: PayeeEbillsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${PayeeEbillsOperations.baseUrl}/${payeeEbillId}`, {
          queryParams,
          cb,
          returnModel: PayeeEbillsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(PayeeEbillsCreator, PayeeEbillsOperations);
}

module.exports = addPayeeEbillsQueries(PayeeEbills);
