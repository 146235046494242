'use strict';

const addBillerAliasNamesQueries = require('../queries/configs/BillerAliasNames');

function BillerAliasNames({ client }) {
  return {
    baseUrl: '/billeraliasnames',
    returnModel: {
      name: 'billeraliasname',
    },
  };

}

module.exports = addBillerAliasNamesQueries(BillerAliasNames);
