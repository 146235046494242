'use strict';
const { createAddQueries } = require('../addQueries');
const { a2aTransfer } = require('../../datatypes');

const {
  id,
  payerId,
  userId,
  modelId,
  amount,
  microService,
  transaction: {
    sendOn,
    deliver,
    deliverOn,
    status,
    foreverOffOn,
    frequency,
    ipv4,
  },
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/a2atransfers`,
  returnModel: {
    name: 'a2atransfer',
    model: a2aTransfer,
  },
  finders: [
    id,
    userId,
    payerId,
    status,
    sendOn,
    deliver,
    deliverOn,
    microService,
    amount,
    modelId,
    foreverOffOn,
    frequency,
    ipv4,
  ],
  orderBy: [
    id,
    payerId,
    status,
    sendOn,
    microService,
    deliverOn,
    amount,
    deliver,
    ipv4,
  ],
});
