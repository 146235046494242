'use strict';
const { addGetOperation } = require('../../../queries');
const { scheduledTransaction } = require('../../../datatypes');
const { createResources } = require('../../../utils');
const UserScheduledTransactions = require('./UserScheduledTransactions');
const AutopayScheduledTransactions = require('./AutopayScheduledTransactions');
const { decryptFundingAccountNumber, decryptDestinationAccountNumber } = require('../transactionAccountNumberDecrypt');

const ScheduledTransactions = (subResources) => function ({ client, fspId }) {

  const ScheduledTransactionsOperations = {
    returnModel: {
      name: 'schedtran',
      model: scheduledTransaction,
    },
    baseUrl: `/fsps/${fspId}/schedtrans`,

  };

  const ScheduledTransactionsCreator = addGetOperation(client, ScheduledTransactionsOperations, function ScheduledTransaction(scheduledTransactionId) {
    const baseUrl = `${ScheduledTransactionsOperations.baseUrl}/${scheduledTransactionId}`;

    const subresources = createResources(subResources, { client, fspId, baseUrl });

    return {
      userScheduledTransactions: new UserScheduledTransactions({ client, fspId, scheduledTransactionId }),
      autopayScheduledTransactions: new AutopayScheduledTransactions({ client, fspId, scheduledTransactionId }),
      decryptFundingAccountNumber: decryptFundingAccountNumber(client),
      decryptDestinationAccountNumber: decryptDestinationAccountNumber(client),
      ...subresources,
    };
  });

  return Object.assign(ScheduledTransactionsCreator, ScheduledTransactionsOperations);
}

module.exports = ScheduledTransactions;
