'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  userId,
  fspPayerId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'payer',
  },
  finders: [
    id,
    userId,
    fspPayerId,
    createFilterConfig('type', 'payertype', 'string'),
    createFilterConfig('status', 'payerstatus', 'string'),
    createFilterConfig('firstName', 'person:firstName', 'string'),
    createFilterConfig('lastName', 'person:lastName', 'string'),
    createFilterConfig('mobilePhone', 'person:mobilePhone', 'string'),
    createFilterConfig('SSNMask', 'taxIdMask', 'string'),
    createFilterConfig('orgName', 'organization:name', 'string'),
    createFilterConfig('orgPhone', 'organization:phone', 'string'),
    createFilterConfig('orgEmail', 'organization:email', 'string'),
  ],
  orderBy: [
    id,
    'fspPayerId',
    createFilterConfig('firstName', 'person:firstName'),
    createFilterConfig('lastName', 'person:lastName'),
    createFilterConfig('SSNMask', 'taxIdMask'),
    createFilterConfig('taxIdMask', 'taxIdMask'),
    createFilterConfig('city', 'payercity'),
    createFilterConfig('state', 'payerstate'),
    createFilterConfig('payerMobilePhone', 'person:mobilePhone'),
    createFilterConfig('payerEmail', 'person:email'),
    createFilterConfig('orgName', 'organization:name'),
    createFilterConfig('orgPhone', 'organization:phone'),
    createFilterConfig('orgEmail', 'organization:email'),
    createFilterConfig('status', 'payerstatus'),
  ],
});
