let httpClient;

try {
  const fetch = require('node-fetch');

  const nodeFetch = async ({ method, baseURL, url, params, paramsSerializer, headers, data, timeout }) => {
    const queryParams = paramsSerializer(params);

    const fetchResponse = await fetch(`${baseURL}${url}?${queryParams}`, {
      method: method,
      headers: headers,
      body: JSON.stringify(data),
      timeout,
    });

    const sdkResponse = {
      status: fetchResponse.status,
      statusText: fetchResponse.statusText,
      headers: fetchResponse.headers,
      data: await fetchResponse.json(),
    };

    if (fetchResponse.status < 200 || fetchResponse.status >= 300) {
      const error = new Error(sdkResponse.statusText);
      error.response = sdkResponse;
      throw error;
    }
    return sdkResponse;
  };

  httpClient = nodeFetch;
}
// eslint-disable-next-line no-empty
catch (e) {
}


module.exports = httpClient;