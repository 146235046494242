'use strict';

function TokenizedCard({ client }) {

  const TokenizedCardOperations = {
    baseUrl: '/tokenizedcard',
    returnModel: {
      name: 'tokenizedcard',
    },

  };

  const TokenizedCardCreator = function (cardNumber) {
    return {
      create: (data, cb) =>
        client.executeRequest('POST', `${TokenizedCardOperations.baseUrl}/${cardNumber}`, {
          data,
          returnModel: TokenizedCardOperations.returnModel,
          cb,
        }),
    };
  };

  return Object.assign(TokenizedCardCreator, TokenizedCardOperations);
}

module.exports = TokenizedCard;
