'use strict';

function TransactionHistoryResearchRequests({ client, fspId, transactionHistoryId }) {
  return {
    returnModel: {
      name: 'transactionresearch',
    },
    baseUrl: `/fsps/${fspId}/histtrans/${transactionHistoryId}/transactionresearch`,

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };
}

module.exports = TransactionHistoryResearchRequests;
