'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  fspId: fspIdConfig,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/topbillers`,
  returnModel: {
    name: 'topbiller',
  },
  finders: [
    id,
    fspIdConfig,
    createFilterConfig('billerTopId', 'billertopid', 'id'),
    createFilterConfig('billerSetId', 'billersetid', 'id'),
    createFilterConfig('billerId', 'billerid', 'id'),
  ],
  orderBy: [
    id,
    fspIdConfig,
    createFilterConfig('billerTopId', 'billertopid'),
    createFilterConfig('billerSetId', 'billersetid'),
    createFilterConfig('billerId', 'billerid'),
  ],
});
