'use strict';

const { addGetOperation } = require('../../queries');
const addUserModelsQueries = require('../../queries/configs/UserModels');

function UserModels({ client, fspId }) {

  const UserModelsOperations = {
    returnModel: {
      name: 'userModel',
    },
    baseUrl: `/fsps/${fspId}/usermodels`,

  };

  const UserModelsCreator = addGetOperation(client, UserModelsOperations, function (userModelId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${UserModelsOperations.baseUrl}/${userModelId}`, {
          data,
          queryParams,
          cb,
          returnModel: UserModelsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${UserModelsOperations.baseUrl}/${userModelId}`, {
          queryParams,
          cb,
          returnModel: UserModelsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(UserModelsCreator, UserModelsOperations);
}

module.exports = addUserModelsQueries(UserModels);
