'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  payeeId,
  billerId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/billsavingoffers`,
  returnModel: {
    name: 'billsavingoffer',
  },
  finders: [
    id,
    payerId,
    userId,
    payeeId,
    billerId,
    createFilterConfig('allAttribs', 'allattribs', 'string'),
    createFilterConfig('offerStatus', 'offerstatus', 'string'),
    createFilterConfig('payeeName', 'payeename', 'string'),
    createFilterConfig('billSavingBillerId', 'bsbillerid', 'id'),
  ],
  orderBy: [
    id,
    payerId,
    userId,
    payeeId,
    billerId,
    createFilterConfig('billSavingBillerId', 'bsbillerid', 'id'),
    createFilterConfig('offerStatus', 'offerstatus', 'string'),
    createFilterConfig('payeeName', 'payeename', 'string'),
  ],
});
