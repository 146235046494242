'use strict';

const { cutOffDates } = require('../../datatypes');

function CutOffDates({ client, fspId }) {
  return function (transactionType) {

    const CutOffDatesOperations = {
      returnModel: {
        name: 'bizdates',
        model: cutOffDates,
      },
      baseUrl: `/fsps/${fspId}/${transactionType}bizdates`,

    };

    return {
      get: async function (cb) {
        return client.executeRequest('GET', `${CutOffDatesOperations.baseUrl}`, {
          cb,
          returnModel: CutOffDatesOperations.returnModel,
        });
      },
    };
  };
}

module.exports = CutOffDates;
