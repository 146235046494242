module.exports = function validateToken({ client, fspId }) {
  return function (ssoToken, cb) {
    return client.executeRequest('POST', `/fsps/${fspId}/ssotoken`, {
      cb,
      queryParams: { ssotoken: ssoToken },
      returnModel: {
        name: 'sso',
      },
    });
  };
}