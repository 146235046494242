'use strict';

const { prop, uniq } = require('ramda');
const { recipient } = require('../../../datatypes');
const { appendFilter } = require('../../../queries');
const addRecipientsQueries = require('../../../queries/configs/Recipients');

function Recipients({ client, fspId, payerId }) {
  return {
    returnModel: {
      name: 'recipient',
      model: recipient,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/recipients`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {

      return client.executeRequest('POST', this.baseUrl, {
        data: Object.assign({ microService: 'p2p' }, data),
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },

    createCheckRecipient: function createCheckRecipient(data, { userId }, cb) {
      return client.fsps(fspId).payers(payerId).payees.create(Object.assign({ elecOrCheck: 'check' }, data), { userId }, cb);
    },

    getGroups: async function getGroups() {
      const recipients = await this.getAll();
      return uniq(recipients.map(prop('group'))).sort();
    },
  };
}

module.exports = addRecipientsQueries(Recipients);
