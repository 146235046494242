'use strict';
const { addGetOperation } = require('../queries');

const addBillersQueries = require('../queries/configs/Billers');

function Billers({ client }) {
  const BillersOperations = {
    baseUrl: '/billers',
    returnModel: {
      name: 'biller',
    },
  };


  const BillersCreator = addGetOperation(client,
    BillersOperations,
    function (billerTopFspId) {
      return {
        update: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${BillersOperations.baseUrl}/${billerTopFspId}`, {
            data,
            queryParams,
            cb,
            returnModel: BillersOperations.returnModel,
          });
        },
      };
    },
  );

  return Object.assign(BillersCreator, BillersOperations);
}

module.exports = addBillersQueries(Billers);
