'use strict';

const { addGetOperation } = require('../../queries');
const addModelBillPaymentsQueries = require('../../queries/configs/ModelBillPayments');
const { billPayment, modelBillPayment } = require('../../datatypes');

function ModelBillPayments({ client, fspId }) {

  const ModelBillPaymentsOperations = {
    returnModel: {
      name: 'modelbillpayment',
      model: modelBillPayment,
    },
    baseUrl: `/fsps/${fspId}/modelbillpayments`,

  };

  const ModelBillPaymentsCreator = addGetOperation(client, ModelBillPaymentsOperations, function ModelBillPayment(billPaymentId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${ModelBillPaymentsOperations.baseUrl}/${billPaymentId}`, {
          queryParams,
          cb,
          returnModel: ModelBillPaymentsOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelBillPaymentsOperations.baseUrl}/${billPaymentId}`, {
          data,
          queryParams,
          cb,
          returnModel: ModelBillPaymentsOperations.returnModel,
        });
      },
      toSingle: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelBillPaymentsOperations.baseUrl}/${billPaymentId}/modeltosingle`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'billpayment',
            model: billPayment,
          },
        });
      },
    };
  });

  return Object.assign(ModelBillPaymentsCreator, ModelBillPaymentsOperations);
}

module.exports = addModelBillPaymentsQueries(ModelBillPayments);
