'use strict';

const { addGetOperation } = require('../../queries');

function ModelInfo({ client, fspId }) {

  const ModelInfoOperations = {
    returnModel: {
      name: 'modelinfo',
    },
    baseUrl: `/fsps/${fspId}/modelinfo`,

  };

  const ModelInfoCreator = addGetOperation(client, ModelInfoOperations, function SingleModelInfo() {
    return {
    };
  });

  return Object.assign(ModelInfoCreator, ModelInfoOperations);
}

module.exports = ModelInfo;
