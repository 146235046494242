'use strict';

const { addGetOperation } = require('../../queries');

function PayerUserSettingss({ client, fspId }) {

  const PayerUserSettingssOperations = {
    returnModel: {
      name: 'payeruser',
    },
    baseUrl: `/fsps/${fspId}/payerusersettings`,

  };

  const PayerUserSettingssCreator = addGetOperation(client, PayerUserSettingssOperations, function PayerUserSettings(payerUserId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${PayerUserSettingssOperations.baseUrl}/${payerUserId}`, {
          data,
          queryParams,
          cb,
          returnModel: PayerUserSettingssOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(PayerUserSettingssCreator, PayerUserSettingssOperations);
}

module.exports = PayerUserSettingss;
