'use strict';

const { addGetOperation } = require('../../queries');
const addIavRequestsQueries = require('../../queries/configs/IavRequests');

function IavRequests({ client, fspId }) {

  const IavRequestsOperations = {
    returnModel: {
      name: 'iavrequests',
    },
    baseUrl: `/fsps/${fspId}/iavrequests`,
  };

  const IavRequestsCreator = addGetOperation(client, IavRequestsOperations, function IavRequest() {
    return {
    };
  });

  return Object.assign(IavRequestsCreator, IavRequestsOperations);
}

module.exports = addIavRequestsQueries(IavRequests);
