'use strict';

const { addGetOperation } = require('../../queries');
const addPayerUsersQueries = require('../../queries/configs/PayerUsers');

function PayerUsers({ client, fspId }) {

  const PayerUsersOperations = {
    returnModel: {
      name: 'payeruser',
    },
    baseUrl: `/fsps/${fspId}/payerusers`,

  };

  const PayerUsersCreator = addGetOperation(client, PayerUsersOperations, function PayerUser(payerUserId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${PayerUsersOperations.baseUrl}/${payerUserId}`, {
          data,
          queryParams,
          cb,
          returnModel: PayerUsersOperations.returnModel,
        });
      },
      updateStatus: function (data, queryParams, cb) {
        return this.update(data, queryParams, cb);
      },

    };
  });

  return Object.assign(PayerUsersCreator, PayerUsersOperations);
}

module.exports = addPayerUsersQueries(PayerUsers);
