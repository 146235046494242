'use strict';
const { createAddQueries } = require('../addQueries');
const { recipient } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  microService,
  deletedOnly,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/recipients`,
  returnModel: {
    name: 'recipient',
    model: recipient,
  },
  finders: [
    id,
    payerId,
    userId,
    microService,
    deletedOnly,
    createFilterConfig('nickName', 'nickname'),
    createFilterConfig('status', 'recipientStatus', 'string'),
    createFilterConfig('group', 'group', 'string'),
    createFilterConfig('p2pDirectOffOn', 'p2pdirectoffon', 'string'),
    createFilterConfig('venmoReciver', 'venmoReciver', 'string'),
    createFilterConfig('venmoRecipientType', 'venmoRecipientType', 'string'),
  ],
  orderBy: [
    createFilterConfig('firstName', 'firstname'),
    createFilterConfig('lastName', 'lastname'),
    createFilterConfig('name', ['firstname', 'lastname']),
    createFilterConfig('nickName', 'nickname'),
    createFilterConfig('status', 'recipientStatus'),
    createFilterConfig('group', 'group'),
  ],
});
