'use strict';

const { addGetOperation } = require('../../queries');
const addUserRecipientsQueries = require('../../queries/configs/UserRecipients');

function UserRecipients({ client, fspId }) {

  const UserRecipientsOperations = {
    returnModel: {
      name: 'userRecipient',
    },
    baseUrl: `/fsps/${fspId}/userrecipients`,

  };

  const UserRecipientsCreator = addGetOperation(client, UserRecipientsOperations, function UserRecipient(ddaId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${UserRecipientsOperations.baseUrl}/${ddaId}`, {
          data,
          queryParams,
          cb,
          returnModel: UserRecipientsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${UserRecipientsOperations.baseUrl}/${ddaId}`, {
          queryParams,
          cb,
          returnModel: UserRecipientsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(UserRecipientsCreator, UserRecipientsOperations);
}

module.exports = addUserRecipientsQueries(UserRecipients);
