const { path } = require('ramda');
const { adaptPromiseThen } = require('../utils');

const decryptField = async ({ client, id, fieldName, resourceDefinition, queryParams = {}, cb }) => {
  const handleResponse = objects => {
    const value = path([0, fieldName], objects);
    if(!value) {
      throw new Error(`Cannot decrypt field ${fieldName} for id ${id}`);
    }
    return value;
  };

  return client.executeRequest('GET', resourceDefinition.baseUrl, {
    queryParams: Object.assign({
      id: id,
      attributes: fieldName,
      expand: 'none',
    }, queryParams),
    cb: adaptPromiseThen(handleResponse, cb),
    returnModel: { name: resourceDefinition.returnModel.name },
  })
    .then(handleResponse);
};

module.exports = {
  decryptField,
};
