
module.exports = function notifyIavLoginRequired({ client, baseUrl }) {
  return function (queryParams, cb) {
    return client.executeRequest('POST', `${baseUrl}/iavloginrequired`, {
      cb,
      queryParams,
      returnModel: {
        name: 'iavloginrequired',
      },
    });
  };
}