'use strict';
const { createAddQueries } = require('../addQueries');
const { scheduledTransaction } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  modelId,
  payeeId,
  recipientId,
  ddaId,
  externalDdaId,
  amount,
  microService,
  deletedOnly,
  transaction: {
    transactionType,
    sendOn,
    deliver,
    deliverOn,
    status,
    foreverOffOn,
    frequency,
    ipv4,
  },
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/schedtrans`,
  returnModel: {
    name: 'schedtran',
    model: scheduledTransaction,
  },
  finders: [
    id,
    userId,
    payerId,
    payeeId,
    status,
    deliver,
    frequency,
    microService,
    modelId,
    recipientId,
    ddaId,
    externalDdaId,
    foreverOffOn,
    sendOn,
    deliverOn,
    transactionType,
    amount,
    deletedOnly,
    ipv4,
    createFilterConfig('alltrans', 'alltrans', 'string'),
    createFilterConfig('autopayOffOn', 'autopayoffon', 'string'),
    createFilterConfig('confirmNumber', 'confirmnbr', 'string'),
    createFilterConfig('fundingAccount', 'fundingaccount', 'string'),
    createFilterConfig('destination', 'destination', 'string'),
    createFilterConfig('p2pDirectOffOn', 'p2pdirectoffon', 'string'),
    createFilterConfig('suspendedP2p', 'suspendedp2p', 'string'),
    createFilterConfig('ssoTrans', 'ssotrans', 'string'),
  ],
  orderBy: [
    id,
    userId,
    payeeId,
    status,
    deliver,
    frequency,
    microService,
    modelId,
    recipientId,
    ddaId,
    externalDdaId,
    foreverOffOn,
    sendOn,
    deliverOn,
    transactionType,
    amount,
    ipv4,
    createFilterConfig('confirmNumber', 'confirmnbr'),
    createFilterConfig('fundingAccount', 'fundingaccount'),
    createFilterConfig('destination', 'destination'),
  ],
});
