'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  fspId: fspIdConfig,
  payerId,
  userId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  wrapInArrayIfNeeded: true,
  baseUrl: ({ fspId }) => `/fsps/${fspId}/fspconfigs`,
  returnModel: {
    name: 'fspconfig',
  },
  finders: [
    id,
    userId,
    payerId,
    createFilterConfig('allAttribs', 'allattribs', 'string'),
  ],
  orderBy: [
    id,
    fspIdConfig,
  ],
});
