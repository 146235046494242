'use strict';

function PreAuthorizedCard({ client }) {

  const PreAuthorizedCardOperations = {
    baseUrl: '/preauthorizedcard',
    returnModel: {
      name: 'preauthorizedcard',
    },

  };

  const PreAuthorizedCardCreator = function (cardNumber) {
    return {
      verify: (data, cb) =>
        client.executeRequest('POST', `${PreAuthorizedCardOperations.baseUrl}/${cardNumber}`, {
          data,
          returnModel: PreAuthorizedCardOperations.returnModel,
          cb,
        }),
    };
  };

  return Object.assign(PreAuthorizedCardCreator, PreAuthorizedCardOperations);
}

module.exports = PreAuthorizedCard;
