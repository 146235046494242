'use strict';

function UserAlerts({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'useralerts',
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/useralerts`,

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = UserAlerts;
