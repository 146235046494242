'use strict';
const { createAddQueries } = require('../addQueries');
const { externalDda } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  microService,
  deletedOnly,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/externalddas`,
  returnModel: {
    name: 'externaldda',
    model: externalDda,
  },
  finders: [
    id,
    userId,
    payerId,
    microService,
    deletedOnly,
    createFilterConfig('externalDdaStatus', 'externalddastatus', 'string'),
    createFilterConfig('iavAccountId', 'iavaccountid', 'string'),
  ],
  orderBy: [
    id,
    userId,
    payerId,
    createFilterConfig('externalDdaStatus', 'externalddastatus'),
    createFilterConfig('iavAccountId', 'iavaccountid', 'string'),
  ],
});
