const { type: typeOfValue, isNil, identity, compose } = require('ramda');
const { adaptPromiseCatch, capitalize, handleNoElements } = require('../utils');

const wrapIntoArray = objectOrArray =>
  typeOfValue(objectOrArray) === 'Array' ? objectOrArray : [ objectOrArray ];

function addQueries(resourceCreator, config) {
  return function () {
    const { client } = arguments[0];

    validateConfig(config);

    const wrapArrayIfNeeded = config.wrapInArrayIfNeeded ? wrapIntoArray : identity;
    const resource = new resourceCreator(...arguments);

    if (!config.removeGetAllOperation) {
      resource.getAll = function (cb) {
        const getAllLimit = client.getKey('getAllLimit');
        const queryParams = getAllLimit && { limit: getAllLimit };
        return client.executeRequest('GET', resource.baseUrl, {
          cb: adaptPromiseCatch(handleNoElements, cb),
          returnModel: config.returnModel,
          queryParams,
        })
          .then(wrapArrayIfNeeded)
          .catch(handleNoElements);
      };
    }

    resource.queryOps = createQueryOps(config.finders, config.orderBy);

    const url = resolveFuncOrValue(config.baseUrl || resource.baseUrl, arguments);

    const wrapArrayInFunction = (generatorOrOperations) =>
      typeOfValue(generatorOrOperations) === 'Array' ? () => generatorOrOperations : generatorOrOperations;

    const applyFilters = resource.queriesFilter || identity;
    const getGenerator = compose(applyFilters, wrapArrayInFunction);

    resource.query = (generatorOrOperations, cb) =>
      executeQuery(client, url, resource, config.returnModel, getGenerator(generatorOrOperations), cb)
        .then(wrapArrayIfNeeded)
        .catch(handleNoElements);

    normalizeFinderDefinitions(config.finders).forEach(({ name }) =>
      resource[`findBy${capitalize(name)}`] = function (value, cb) {
        return this.query(_ => [ _[name](value) ], cb);
      },
    );

    return resource;
  };
}

const resolveFuncOrValue = (funcOrValue, args) =>
  typeOfValue(funcOrValue) === 'Function' ? funcOrValue(...args) : funcOrValue;

const validateConfig = ({ finders = [], orderBy = [], returnModel }) => {
  const failAt = (operation, i) => {
    throw new Error(`${operation} not defined for ${returnModel.name} at index ${i}`);
  };

  finders.forEach((f, i) => isNil(f) && failAt('finder', i));
  orderBy.forEach((f, i) => isNil(f) && failAt('orderBy', i));
};

function executeQuery(client, url, resource, returnModel, generator, cb) {
  return client.executeRequest('GET', url, {
    queryParams: buildQueryParams(generator, resource),
    cb: adaptPromiseCatch(handleNoElements, cb),
    returnModel,
  });
}

const buildQueryParams = (generator, resource) => {
  const operations = generator(resource.queryOps);
  return convertOpsToQueryParams(operations);
};

const convertOpsToQueryParams = (ops) => {
  const params = {};
  ops.forEach(({ op, queryParamName, value, direction, fields }) => {
    if (op === 'equal') {
      params[queryParamName] = value;
    }
    if (op === 'like') {
      params[`like(${queryParamName})`] = value;
    }
    if (op === 'range') {
      params[`startwith(${queryParamName})`] = value.from;
      params[`endwith(${queryParamName})`] = value.to;
    }
    if (op === 'orderBy' && typeOfValue(queryParamName) !== 'Array') {
      const newOrder = createNewOrder(params, queryParamName, direction);
      params.order = newOrder;
    }
    if (op === 'orderBy' && typeOfValue(queryParamName) === 'Array') {
      queryParamName.forEach(qpName => {
        const newOrder = createNewOrder(params, qpName, direction);
        params.order = newOrder;
      })
    }
    if (op === 'offset' || op === 'limit') {
      params[op] = value;
    }
    if (op === 'expand') {
      params.expand = fields.join(',');
    }
    if (op === 'attributes') {
      params.attributes = fields.join(',');
    }
  });
  return params;
};

const createNewOrder = (params, queryParamName, direction) => {
  return [params.order, `${queryParamName}:${direction}`]
    .filter((i) => !isNil(i))
    .join(',');
}

function expandRangeParams() {
  const argumentsArray = typeOfValue(arguments[0]) === 'Array' ? arguments[0] : arguments;
  const [from, to] = [ ...argumentsArray ];
  return { from, to };
}

const createQueryOps = (finders, orderBy) => {
  const ops = {};

  normalizeFinderDefinitions(finders).forEach(({ name, queryParamName, type, value }) => {
    if ([ 'fixedValue' ].includes(type)) {
      ops[name] = () => ({
        op: 'equal',
        queryParamName,
        value,
      });
    }

    if (['string', 'id', 'amount', 'date'].includes(type)) {
      ops[name] = (v) => ({
        op: 'equal',
        queryParamName,
        value: v,
      });
    }

    if (['string', 'id'].includes(type)) {
      ops[`${name}Like`] = (v) => ({
        op: 'like',
        queryParamName,
        value: v,
      });
    }

    if (['id', 'amount', 'date'].includes(type)) {
      ops[`${name}Range`] = function () {
        return ({
          op: 'range',
          queryParamName,
          value: expandRangeParams(...arguments),
        });
      };
    }

  });

  normalizeFinderDefinitions(orderBy).forEach(({ name, queryParamName }) => {
    ops[`orderBy${capitalize(name)}`] = (direction = 'asc') => ({
      op: 'orderBy',
      queryParamName,
      direction,
    });
  });

  ops.offset = (value) => ({
    op: 'offset',
    value,
  });

  ops.limit = (value) => ({
    op: 'limit',
    value,
  });

  ops.expand = (fields) => ({
    op: 'expand',
    fields,
  });

  ops.attributes = (fields) => ({
    op: 'attributes',
    fields,
  });

  return ops;
};

const normalizeFinderDefinitions = (finders) => (finders || []).map(
  finder => typeOfValue(finder) === 'Object' ? finder : { name: finder, queryParamName: finder });

module.exports = {
  addQueries,
  createAddQueries: config => resourceCreator => addQueries(resourceCreator, config),
  _buildQueryParams: convertOpsToQueryParams,
  _createQueryOps: createQueryOps,
  _handleNoElements: handleNoElements,
  _validateConfig: validateConfig,
  _wrapIntoArray: wrapIntoArray,
};
