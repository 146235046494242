'use strict';
const { defaultTo } = require('ramda');

const decryptDdaAccountNumber = (client, transaction, cb) =>
  client
    .fsps(transaction.fspId)
    .ddas(transaction.ddaId)
    .decryptAccountNumber(cb);

const decryptTransferToDdaAccountNumber = (client, transaction, cb) =>
  client
    .fsps(transaction.fspId)
    .ddas(transaction.transferToDdaId)
    .decryptAccountNumber(cb);

const decryptExternalDdaAccountNumber = (client, transaction, cb) =>
  client
    .fsps(transaction.fspId)
    .externalDdas(transaction.externalDdaId)
    .decryptAccountNumber(cb);

const decryptPayeeBillerAccountNumber = (client, transaction, cb) =>
  client
    .fsps(transaction.fspId)
    .payees(transaction.payeeId)
    .decryptAccountNumber(cb);

const decryptRecipientAccountNumber = (client, transaction, cb) =>
  client
    .fsps(transaction.fspId)
    .recipients(transaction.recipientId)
    .decryptAccountNumber(cb);

const noAccountNumber = async () => undefined;

const fundingDecryptFunctions = {
  a2ain: decryptExternalDdaAccountNumber,
  a2anew: decryptExternalDdaAccountNumber,
  a2aout: decryptDdaAccountNumber,
  a2axfer: decryptDdaAccountNumber,
  p2p: decryptDdaAccountNumber,
  bp: decryptDdaAccountNumber,
};

const getFundingDecrypt = (transactionType) =>
  defaultTo(noAccountNumber)(fundingDecryptFunctions[transactionType.toLowerCase()]);

const destinationDecryptFunctions = {
  a2ain: decryptDdaAccountNumber,
  a2anew: decryptDdaAccountNumber,
  a2aout: decryptExternalDdaAccountNumber,
  a2axfer: decryptTransferToDdaAccountNumber,
  p2p: decryptRecipientAccountNumber,
  bp: decryptPayeeBillerAccountNumber,
};

const getDestinationDecrypt = (transactionType) =>
  defaultTo(noAccountNumber)(destinationDecryptFunctions[transactionType.toLowerCase()]);

const decryptFundingAccountNumber = (client) => (transaction, cb) =>
  getFundingDecrypt(transaction.transactionType)(client, transaction, cb);

const decryptDestinationAccountNumber = (client) => (transaction, cb) =>
  getDestinationDecrypt(transaction.transactionType)(client, transaction, cb);

module.exports = {
  decryptFundingAccountNumber,
  decryptDestinationAccountNumber,
};
