'use strict';

const { addGetOperation } = require('../../queries');
const addBillPaymentsQueries = require('../../queries/configs/BillPayments');
const { billPayment, modelBillPayment } = require('../../datatypes');

function BillPayments({ client, fspId }) {

  const BillPaymentsOperations = {
    returnModel: {
      name: 'billpayment',
      model: billPayment,
    },
    baseUrl: `/fsps/${fspId}/billpayments`,

  };

  const BillPaymentsCreator = addGetOperation(client, BillPaymentsOperations, function BillPayment(billPaymentId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${BillPaymentsOperations.baseUrl}/${billPaymentId}`, {
          queryParams,
          cb,
          returnModel: BillPaymentsOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${BillPaymentsOperations.baseUrl}/${billPaymentId}`, {
          data,
          queryParams,
          cb,
          returnModel: BillPaymentsOperations.returnModel,
        });
      },
      toModel: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${BillPaymentsOperations.baseUrl}/${billPaymentId}/singletomodel`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'modelbillpayment',
            model: modelBillPayment,
          },
        });
      },
    };
  });

  return Object.assign(BillPaymentsCreator, BillPaymentsOperations);
}

module.exports = addBillPaymentsQueries(BillPayments);
