'use strict';

const { addGetOperation } = require('../../queries');
const addInternalTransfersQueries = require('../../queries/configs/InternalTransfers');
const { internalTransfer, modelInternalTransfer } = require('../../datatypes');

function InternalTransfers({ client, baseUrl }) {

  const InternalTransfersOperations = {
    returnModel: {
      name: 'internaltransfer',
      model: internalTransfer,
    },
    baseUrl: `${baseUrl}/internaltransfers`,
  };

  const InternalTransfersCreator = addGetOperation(client, InternalTransfersOperations, function InternalTransfer(internalTransferId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${InternalTransfersOperations.baseUrl}/${internalTransferId}`, {
          queryParams,
          cb,
          returnModel: InternalTransfersOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${InternalTransfersOperations.baseUrl}/${internalTransferId}`, {
          data,
          queryParams,
          cb,
          returnModel: InternalTransfersOperations.returnModel,
        });
      },
      toModel: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${InternalTransfersOperations.baseUrl}/${internalTransferId}/singletomodel`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'modelinternaltransfer',
            model: modelInternalTransfer,
          },
        });
      },
    };
  });

  return Object.assign(InternalTransfersCreator, InternalTransfersOperations);
}

module.exports = addInternalTransfersQueries(InternalTransfers);
