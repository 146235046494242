'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'userautopay',
  },
  finders: [
    id,
    payerId,
    userId,
    createFilterConfig('autopayId', 'autopayid', 'id'),
  ],
  orderBy: [
    id,
    payerId,
    userId,
    createFilterConfig('autopayId', 'autopayid', 'id'),
  ],
});
