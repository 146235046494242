'use strict';

const { addGetOperation } = require('../../queries');
const addFspConfigsQueries = require('../../queries/configs/FspConfigs');

function FspConfigs({ client, fspId }) {

  const FspConfigsOperations = {
    returnModel: {
      name: 'fspconfig',
    },
    baseUrl: `/fsps/${fspId}/fspconfigs`,
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

  const FspConfigsCreator = addGetOperation(client, FspConfigsOperations,
    function FspConfig(id) {
      return {
        update: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${FspConfigsOperations.baseUrl}/${id}`, {
            data,
            queryParams,
            cb,
            returnModel: FspConfigsOperations.returnModel,
          });
        },
      };
    },
  );

  return Object.assign(FspConfigsCreator, FspConfigsOperations);
}

module.exports = addFspConfigsQueries(FspConfigs);
