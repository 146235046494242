'use strict';
const { createResources } = require('../../utils');
const { addGetOperation } = require('../../queries');

const Fsps = (subResources) => function ({ client }) {
  const FspOperations = {
    baseUrl: '/fsps',
    returnModel: {
      name: 'fsp',
    },
  };

  const FspCreator = addGetOperation(client, FspOperations, function (fspId) {
    const baseUrl = `/fsps/${fspId}`;

    return createResources(subResources, { client, fspId, baseUrl });
  });

  return Object.assign(FspCreator, FspOperations);
};

module.exports = Fsps;
