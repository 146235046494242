'use strict';

const { addGetOperation } = require('../../queries');
const addNotificationsQueries = require('../../queries/configs/Notifications');

function Notifications({ client, fspId }) {

  const NotificationsOperations = {
    returnModel: {
      name: 'notification',
    },
    baseUrl: `/fsps/${fspId}/notifications`,
  };

  const NotificationsCreator = addGetOperation(client, NotificationsOperations, function Notification(notificationId) {
    return {

    };
  });

  return Object.assign(NotificationsCreator, NotificationsOperations);
}

module.exports = addNotificationsQueries(Notifications);
