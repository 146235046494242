'use strict';

const { appendFilter } = require('../../../queries');
const addPayeeGroupsQueries = require('../../../queries/configs/PayeeGroups');

function PayeeGroups({ client, fspId, payeeId }) {

  return {
    returnModel: {
      name: 'payeegroup',
    },
    baseUrl: `/fsps/${fspId}/payees/${payeeId}/payeegroups`,
    queriesFilter: appendFilter('payeeId', payeeId),

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addPayeeGroupsQueries(PayeeGroups);
