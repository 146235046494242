'use strict';

function CreditAutopays({ client, ddaId, baseUrl }) {

  return {
    returnModel: {
      name: 'creditautopay',
    },
    baseUrl: `${baseUrl}/${ddaId}/creditautopays`,

    create: function create(data, queryParams, cb) {

      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },

  };
}

module.exports = CreditAutopays;
