const { lowerCaseField, computeField, renameField, fieldToJson } = require('./api/transformations');

const addModuleType = computeField('moduleType', ({ transactionType, transferType }) => {
  switch (transactionType) {
    case 'p2p':
      return 'p2p';
    case 'bp':
      return 'bp';
    case 'a2aout':
    case 'a2ain':
    case 'a2anew':
    case 'a2axfer':
      return 'a2a';
    default:
      return ['a2ain', 'a2aout'].includes(transferType) ? 'a2a' : null;
  }
});

const transactionTypeLowerCase = lowerCaseField('transactionType');

const payeruserdda = {
  payer: {},
  user: {},
  payeruser: {},
  ddauserdda: {},
  sso: {
    links: {},
  },
};

const recipient = {
  userRecipient: {},
};

const payee = {
  userPayee: {},
};

const externalDda = {
  userExternalDda: {},
};

const invoice = {
  detail: {},
};

const billPayment = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
};

const p2pPayment = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
};

const a2aTransfer = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
};

const internalTransfer = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
};

const scheduledTransaction = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
  userSchedTran: {},
};

const fspScheduledTransaction = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
  userSchedTran: {},
};

const transactionHistory = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  invoices: invoice,
};

const userModel = {
};

const modelP2pPayment = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  userModel: userModel,
};

const modelA2aTransfer = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  userModel: userModel,
};

const modelInternalTransfer = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  userModel: userModel,
};

const modelBillPayment = {
  _transformations: [transactionTypeLowerCase, addModuleType],
  userModel: userModel,
};

const urlLink = {
  _transformations: [ fieldToJson('attribJson') ],
};

const cutOffDates = {
  _transformations: [
    renameField('bizdate', 'currentCutOffDate'),
    renameField('bizdateExpiresInSec', 'currentCutOffDateExpiresInSec'),
    renameField('nextBizdate', 'nextCutOffDate'),
  ],
};

module.exports = {
  a2aTransfer,
  billPayment,
  cutOffDates,
  externalDda,
  internalTransfer,
  modelA2aTransfer,
  modelBillPayment,
  modelInternalTransfer,
  modelP2pPayment,
  p2pPayment,
  payee,
  payeruserdda,
  recipient,
  scheduledTransaction,
  fspScheduledTransaction,
  transactionHistory,
  urlLink,
};
