'use strict';

const { addGetOperation } = require('../../queries');
const addEbillAutopaysQueries = require('../../queries/configs/EbillAutopays');

function EbillAutopays({ client, baseUrl }) {

  const EbillAutopaysOperations = {
    returnModel: {
      name: 'ebillautopay',
    },
    baseUrl: `${baseUrl}/ebillautopays`,

  };

  const EbillAutopaysCreator = addGetOperation(client, EbillAutopaysOperations, function EbillAutopay(ebillAutopayId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${EbillAutopaysOperations.baseUrl}/${ebillAutopayId}`, {
          queryParams,
          cb,
          returnModel: EbillAutopaysOperations.returnModel,
        });
      },

      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${EbillAutopaysOperations.baseUrl}/${ebillAutopayId}`, {
          data,
          queryParams,
          cb,
          returnModel: EbillAutopaysOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(EbillAutopaysCreator, EbillAutopaysOperations);
}

module.exports = addEbillAutopaysQueries(EbillAutopays);
