'use strict';

const { modelA2aTransfer } = require('../../../datatypes');
const { appendFilter } = require('../../../queries');
const addModelA2aTransfersQueries = require('../../../queries/configs/ModelA2aTransfers');

function ModelA2aTransfers({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'modela2atransfer',
      model: modelA2aTransfer,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/modela2atransfers`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addModelA2aTransfersQueries(ModelA2aTransfers);
