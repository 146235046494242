'use strict';

const { addGetOperation } = require('../../queries');
const addUserAutopaysQueries = require('../../queries/configs/UserAutopays');

function UserAutopays({ client, baseUrl }) {

  const UserAutopaysOperations = {
    returnModel: {
      name: 'userautopay',
    },
    baseUrl: `${baseUrl}/userautopays`,

  };

  const UserAutopaysCreator = addGetOperation(client, UserAutopaysOperations, function UserAutopay(userAutopayId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${UserAutopaysOperations.baseUrl}/${userAutopayId}`, {
          data,
          queryParams,
          cb,
          returnModel: UserAutopaysOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${UserAutopaysOperations.baseUrl}/${userAutopayId}`, {
          queryParams,
          cb,
          returnModel: UserAutopaysOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(UserAutopaysCreator, UserAutopaysOperations);
}

module.exports = addUserAutopaysQueries(UserAutopays);
