'use strict';
const { createAddQueries } = require('../addQueries');
const { modelInternalTransfer } = require('../../datatypes');

const {
  id,
  payerId,
  userId,
  transaction: {
    transferToDdaId,
    transferFromDdaId,
    foreverOffOn,
    frequency,
  },
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/modelinternaltransfers`,
  returnModel: {
    name: 'modelinternaltransfer',
    model: modelInternalTransfer,
  },
  finders: [
    id,
    userId,
    payerId,
    transferToDdaId,
    transferFromDdaId,
    foreverOffOn,
    frequency,
  ],
  orderBy: [
    id,
    payerId,
    foreverOffOn,
    frequency,
  ],
});
