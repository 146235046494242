'use strict';

const { addGetOperation } = require('../../queries');
const addP2pPaymentsQueries = require('../../queries/configs/P2pPayments');
const { modelP2pPayment, p2pPayment } = require('../../datatypes');

function P2PPayments({ client, fspId }) {

  const P2PPaymentsOperations = {
    returnModel: {
      name: 'p2ppayment',
      model: p2pPayment,
    },
    baseUrl: `/fsps/${fspId}/p2ppayments`,

  };

  const P2PPaymentsCreator = addGetOperation(client, P2PPaymentsOperations, function P2pPayment(p2pPaymentId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${P2PPaymentsOperations.baseUrl}/${p2pPaymentId}`, {
          queryParams,
          cb,
          returnModel: P2PPaymentsOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${P2PPaymentsOperations.baseUrl}/${p2pPaymentId}`, {
          data,
          queryParams,
          cb,
          returnModel: P2PPaymentsOperations.returnModel,
        });
      },
      toModel: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${P2PPaymentsOperations.baseUrl}/${p2pPaymentId}/singletomodel`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'modelp2ppayment',
            model: modelP2pPayment,
          },
        });
      },
    };
  });

  return Object.assign(P2PPaymentsCreator, P2PPaymentsOperations);
}

module.exports = addP2pPaymentsQueries(P2PPayments);
