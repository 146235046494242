'use strict';

const addBillerSetNamesQueries = require('../../queries/configs/BillerSetNames');

function BillerSetNames({ fspId }) {

  return {
    returnModel: {
      name: 'billersetnames',
    },
    baseUrl: `/fsps/${fspId}/billersetnames`,
  };
}

module.exports = addBillerSetNamesQueries(BillerSetNames);
