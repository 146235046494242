'use strict';

function BinLookup({ client }) {

  const BinLookupOperations = {
    baseUrl: '/binlookup',
    returnModel: {
      name: 'binlookup',
    },

  };

  const BinLookupCreator = function (cardNumber) {
    return {
      get: (cb) =>
        client.executeRequest('POST', `${BinLookupOperations.baseUrl}/${cardNumber}`, {
          returnModel: BinLookupOperations.returnModel,
          cb,
        }),
    };
  };

  return Object.assign(BinLookupCreator, BinLookupOperations);
}

module.exports = BinLookup;
