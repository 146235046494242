'use strict';

const { addGetOperation } = require('../../queries');
const addModelP2pPaymentsQueries = require('../../queries/configs/ModelP2pPayments');
const { modelP2pPayment, p2pPayment } = require('../../datatypes');

function ModelP2pPayments({ client, fspId }) {

  const ModelP2pPaymentsOperations = {
    returnModel: {
      name: 'modelp2ppayment',
      model: modelP2pPayment,
    },
    baseUrl: `/fsps/${fspId}/modelp2ppayments`,

  };

  const ModelP2pPaymentsCreator = addGetOperation(client, ModelP2pPaymentsOperations, function ModelBillPayment(modelP2pPaymentId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${ModelP2pPaymentsOperations.baseUrl}/${modelP2pPaymentId}`, {
          queryParams,
          cb,
          returnModel: ModelP2pPaymentsOperations.returnModel,
        });
      },
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelP2pPaymentsOperations.baseUrl}/${modelP2pPaymentId}`, {
          data,
          queryParams,
          cb,
          returnModel: ModelP2pPaymentsOperations.returnModel,
        });
      },
      toSingle: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ModelP2pPaymentsOperations.baseUrl}/${modelP2pPaymentId}/modeltosingle`, {
          data,
          queryParams,
          cb,
          returnModel: {
            name: 'p2ppayment',
            model: p2pPayment,
          },
        });
      },
    };
  });

  return Object.assign(ModelP2pPaymentsCreator, ModelP2pPaymentsOperations);
}

module.exports = addModelP2pPaymentsQueries(ModelP2pPayments);
