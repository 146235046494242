'use strict';
const { createAddQueries } = require('../addQueries');
const { modelBillPayment } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  payeeId,
  transaction: {
    deliver,
    status,
    foreverOffOn,
    frequency,
  },
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/modelbillpayments`,
  returnModel: {
    name: 'modelbillpayment',
    model: modelBillPayment,
  },
  finders: [
    id,
    userId,
    payerId,
    payeeId,
    status,
    deliver,
    foreverOffOn,
    frequency,
  ],
  orderBy: [
    id,
    payerId,
    userId,
    payeeId,
    status,
    foreverOffOn,
    deliver,
    frequency,
  ],
});
