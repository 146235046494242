'use strict';

const { addGetOperation } = require('../../queries');
const addCreditAutopaysQueries = require('../../queries/configs/CreditAutopays');

function CreditAutopays({ client, fspId }) {

  const CreditAutopaysOperations = {
    returnModel: {
      name: 'creditautopay',
    },
    baseUrl: `/fsps/${fspId}/creditautopays`,

  };

  const CreditAutopaysCreator = addGetOperation(client, CreditAutopaysOperations, function CreditAutopay(creditAutopayId) {
    return {
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${CreditAutopaysOperations.baseUrl}/${creditAutopayId}`, {
          queryParams,
          cb,
          returnModel: CreditAutopaysOperations.returnModel,
        });
      },

      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${CreditAutopaysOperations.baseUrl}/${creditAutopayId}`, {
          data,
          queryParams,
          cb,
          returnModel: CreditAutopaysOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(CreditAutopaysCreator, CreditAutopaysOperations);
}

module.exports = addCreditAutopaysQueries(CreditAutopays);
