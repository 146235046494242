'use strict';

function UserExternalDdas({ client, fspId, externalDdaId }) {

  return {
    returnModel: {
      name: 'userExternalDda',
    },
    baseUrl: `/fsps/${fspId}/externalddas/${externalDdaId}/userexternalddas`,

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = UserExternalDdas;
