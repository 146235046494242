'use strict';

const { addGetOperation } = require('../../../queries');
const addPayersQueries = require('../../../queries/configs/Payers');

const { payeruserdda } = require('../../../datatypes');
const { decryptField } = require('../../../queries/utils');
const { createResources } = require('../../../utils');

const Payers = (subResources) => function ({ client, fspId }) {

  const PayersOperations = {
    returnModel: {
      name: 'payer',
    },
    baseUrl: `/fsps/${fspId}/payers`,

    registerWithSsoToken: function (data, cb) {
      return this.register(data, { sso: 'on' }, cb);
    },

    register: function (data, queryParams = {}, cb = undefined) {
      return client.executeRequest('POST', `/fsps/${fspId}/payeruserddas`, {
        data,
        queryParams,
        cb,
        returnModel: {
          name: 'payeruserdda',
          model: payeruserdda,
        },
      });
    },
  };

  const PayerCreator = addGetOperation(client, PayersOperations, function Payer(payerId) {
    const config = {
      client,
      baseUrl: PayersOperations.baseUrl,
      fspId,
      payerId,
    };

    return Object.assign(
      {},
      createResources(subResources, config),
      {
        decryptSSN: function (cb) {
          return this.decryptTaxId(cb);
        },

        decryptTaxId: (cb) =>
          decryptField({
            client,
            id: payerId,
            fieldName: 'taxId',
            resourceDefinition: PayersOperations,
            cb,
          }),

        decryptFspPayerId: (cb) =>
          decryptField({
            client,
            id: payerId,
            fieldName: 'fspPayerId',
            resourceDefinition: PayersOperations,
            cb,
          }),

        updateStatus: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${PayersOperations.baseUrl}/${payerId}`, {
            data,
            queryParams,
            cb,
            returnModel: PayersOperations.returnModel,
          });
        },
        otpGenerate: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${PayersOperations.baseUrl}/${payerId}/otp/generate`, {
            data,
            queryParams,
            cb,
            returnModel: { name: 'otps' },
          });
        },

        deleteScheduledTransactions: function ({ payeeId, recipientId, externalDdaId, transactionType, adminUserId, userId }, cb) {
          return client.executeRequest('DELETE', `${PayersOperations.baseUrl}/${payerId}/deletepayerscheds`, {
            queryParams: {
              payeeId,
              recipientId,
              externalDdaId,
              trantype: transactionType,
              adminUserId,
              userId,
            },
            cb,
            returnModel: PayersOperations.returnModel,
          });
        },

        otpSend: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${PayersOperations.baseUrl}/${payerId}/otp/send`, {
            data,
            queryParams,
            cb,
            returnModel: {
              name: 'sendotp',
            },
          });
        },
        otpValidate: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${PayersOperations.baseUrl}/${payerId}/otp/validate`, {
            data,
            queryParams,
            cb,
            returnModel: {
              name: 'validateotp',
            },
          });
        },
        getPayerLimits: function (queryParams, cb) {
          return client.executeRequest('GET', `${PayersOperations.baseUrl}/${payerId}/payerlimits`, {
            queryParams,
            cb,
            returnModel: {
              name: 'payerlimit',
            },
          });
        },
      },
    );
  });

  return Object.assign(PayerCreator, PayersOperations);
};

module.exports = (subResources) => addPayersQueries(Payers(subResources));
