'use strict';

const { addGetOperation } = require('../../../queries');
const addRecipientsQueries = require('../../../queries/configs/Recipients');
const { recipient } = require('../../../datatypes');
const { decryptField } = require('../../../queries/utils');
const { createResources } = require('../../../utils');

const Recipients = (subResources) => function ({ client, fspId }) {

  const RecipientsOperations = {
    returnModel: {
      name: 'recipient',
      model: recipient,
    },
    baseUrl: `/fsps/${fspId}/recipients`,

  };

  const RecipientsCreator = addGetOperation(client, RecipientsOperations, function Recipient(recipientId) {
    const config = {
      client,
      baseUrl: RecipientsOperations.baseUrl,
      fspId,
      recipientId,
    };
    return Object.assign(
      {},
      createResources(subResources, config),
      {
        decryptAccountNumber: (cb) =>
          decryptField({
            client,
            id: recipientId,
            fieldName: 'accountNbr',
            resourceDefinition: RecipientsOperations,
            cb,
          }),
        delete: function (queryParams, cb) {
          return client.executeRequest('DELETE', `${RecipientsOperations.baseUrl}/${recipientId}`, {
            queryParams,
            cb,
            returnModel: RecipientsOperations.returnModel,
          });
        },
        update: function (data, queryParams, cb) {
          return client.executeRequest('POST', `${RecipientsOperations.baseUrl}/${recipientId}`, {
            data,
            queryParams,
            cb,
            returnModel: RecipientsOperations.returnModel,
          });
        },
        updateGroup: function updateGroup(group, queryParams, cb) {
          return this.update({ group }, queryParams, cb);
        },
        updateStatus: function updateStatus({ recipientStatus, recipientStatusText }, queryParams, cb) {
          return this.update({ recipientStatus, recipientStatusText }, queryParams, cb);
        },
        updateDeliveryInstructions: function (data, cb) {
          return client.executeRequest('POST',
            `${RecipientsOperations.baseUrl}/${recipientId}/deliveryinstructions`,
            {
              data,
              cb,
              returnModel: {
                name: 'recipientdeliveryinstructions',
              },
            },
          );
        },
      },
    );
  });

  return Object.assign(RecipientsCreator, RecipientsOperations);
}

module.exports = (subResources) => addRecipientsQueries(Recipients(subResources));
