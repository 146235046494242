'use strict';

const { appendFilter } = require('../../../queries');
const addExternalDdasQueries = require('../../../queries/configs/ExternalDdas');
const { externalDda } = require('../../../datatypes');

function ExternalDdas({ client, fspId, payerId }) {
  return {
    returnModel: {
      name: 'externaldda',
      model: externalDda,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/externalddas`,
    queriesFilter: appendFilter('payerId', payerId),

    create: function create(data, queryParams, cb) {

      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },

  };
}

module.exports = addExternalDdasQueries(ExternalDdas);
