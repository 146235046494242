'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  fspPayerId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'user',
  },
  finders: [
    id,
    payerId,
    fspPayerId,
    createFilterConfig('firstName', 'firstname', 'string'),
    createFilterConfig('lastName', 'lastname', 'string'),
    createFilterConfig('middleName', 'middlename', 'string'),
  ],
  orderBy: [
    'fspPayerId',
    createFilterConfig('firstName', 'firstname'),
    createFilterConfig('lastName', 'lastname'),
    createFilterConfig('middleName', 'middlename'),
    createFilterConfig('fspPayerid', 'fspPayerid'),
  ],
});
