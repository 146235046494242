'use strict';
const { adaptPromiseCatch, handleNoElements } = require('../../../utils');

function BillSavingOffers({ client, baseUrl, payerId }) {

  return {
    returnModel: {
      name: 'billsavingoffer',
    },
    baseUrl: `${baseUrl}/${payerId}/billsavingoffers`,
    query: function query(queryParams, cb) {
      return client.executeRequest('GET', this.baseUrl, {
        queryParams,
        cb: adaptPromiseCatch(handleNoElements, cb),
        returnModel: this.returnModel,
      })
        .catch(handleNoElements);
    },
  };

}

module.exports = BillSavingOffers;
