'use strict';
const { createAddQueries } = require('../addQueries');
const { createFilterConfig } = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'billersetnames',
  },
  finders: [
    createFilterConfig('name', 'name', 'string'),
  ],
  orderBy: [
    createFilterConfig('name', 'name'),
  ],
});
