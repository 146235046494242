'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  transaction: {
    transactionType,
  },
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'useralerts',
  },
  finders: [
    id,
    payerId,
    userId,
    transactionType,
  ],
  orderBy: [
    id,
    payerId,
    userId,
    transactionType,
  ],
});
