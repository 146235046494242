'use strict';

const { a2aTransfer } = require('../../../datatypes');
const { appendFilter } = require('../../../queries');
const addA2aTransfersQueries = require('../../../queries/configs/A2aTransfers');

function A2aTransfers({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'a2atransfer',
      model: a2aTransfer,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/a2atransfers`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data: Object.assign({}, data, { microService: 'a2a' }),
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addA2aTransfersQueries(A2aTransfers);
