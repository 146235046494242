'use strict';
const { createAddQueries } = require('../addQueries');
const { p2pPayment } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  recipientId,
  modelId,
  amount,
  transaction: {
    transactionType,
    sendOn,
    deliver,
    deliverOn,
    status,
    foreverOffOn,
    frequency,
    confirmationNumber,
    ipv4,
  },
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/p2ppayments`,
  returnModel: {
    name: 'p2ppayment',
    model: p2pPayment,
  },
  finders: [
    id,
    userId,
    payerId,
    recipientId,
    status,
    sendOn,
    deliver,
    deliverOn,
    frequency,
    amount,
    foreverOffOn,
    transactionType,
    confirmationNumber,
    ipv4,
    modelId,
  ],
  orderBy: [
    id,
    payerId,
    modelId,
    recipientId,
    status,
    sendOn,
    foreverOffOn,
    deliverOn,
    amount,
    deliver,
    frequency,
    transactionType,
    confirmationNumber,
    ipv4,
  ],
});
