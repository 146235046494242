'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  fspId,
  createdAt,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'payerebilleraccount',
  },
  finders: [
    id,
    createFilterConfig('payerEbillernetEbillerId', 'payerebillernetebillerid', 'id'),
    createFilterConfig('netEbillerAcctId', 'netebilleracctid', 'id'),
  ],
  orderBy: [
    id,
    fspId,
    createdAt,
    createFilterConfig('payerEbillernetEbillerId', 'payerebillernetebillerid'),
    createFilterConfig('ebillernetEbillerId', 'ebillernetebillerid'),
  ],
});
