const resources = {
  achCodes: require('./resources/AchCodes'),
  billerAliasNames: require('./resources/BillerAliasNames'),
  billers: require('./resources/Billers'),
  binLookup: require('./resources/BinLookup'),
  fsps: require('./resources/Fsps')({
    // resources
    a2aTransfers: require('./resources/Fsps/A2aTransfers'),
    autopays: require('./resources/Fsps/Autopays'),
    autopayScheduledTransactions: require('./resources/Fsps/AutopayScheduledTransactions'),
    billerAliasNames: require('./resources/Fsps/BillerAliasNames'),
    billerSetNames: require('./resources/Fsps/BillerSetNames'),
    billPayments: require('./resources/Fsps/BillPayments'),
    billSavingOffers: require('./resources/Fsps/BillSavingOffers'),
    calendars: require('./resources/Fsps/Calendars'),
    creditAutopays: require('./resources/Fsps/CreditAutopays'),
    cutOffDates: require('./resources/Fsps/CutOffDates'),
    ddas: require('./resources/Fsps/Ddas')({
      creditAutopays: require('./resources/Fsps/Ddas/CreditAutopays'),
    }),
    ebillAutopays: require('./resources/Fsps/EbillAutopays'),
    externalDdas: require('./resources/Fsps/ExternalDdas'),
    fspConfigs: require('./resources/Fsps/FspConfigs'),
    iavRequests: require('./resources/Fsps/IavRequests'),
    importantMessages: require('./resources/Fsps/ImportantMessages'),
    insightEvents: require('./resources/Fsps/InsightEvents'),
    internalTransfers: require('./resources/Fsps/InternalTransfers'),
    models: require('./resources/Fsps/Models'),
    modelA2aTransfers: require('./resources/Fsps/ModelA2aTransfers'),
    modelBillPayments: require('./resources/Fsps/ModelBillPayments'),
    modelInfo: require('./resources/Fsps/ModelInfo'),
    modelInternalTransfers: require('./resources/Fsps/ModelInternalTransfers'),
    modelP2pPayments: require('./resources/Fsps/ModelP2pPayments'),
    notifications: require('./resources/Fsps/Notifications'),
    p2pPayments: require('./resources/Fsps/P2PPayments'),
    p2pPaymentLink: require('./resources/Fsps/P2pPaymentLink'),
    payeeEbills: require('./resources/Fsps/PayeeEbills'),
    payees: require('./resources/Fsps/Payees')({
      ebillAutopays: require('./resources/Fsps/Payees/EbillAutopays'),
    }),
    payeeGroups: require('./resources/Fsps/PayeeGroups'),
    payerEbillerAccounts: require('./resources/Fsps/PayerEbillerAccounts'),
    payers: require('./resources/Fsps/Payers')({
      a2aTransfers: require('./resources/Fsps/Payers/PayersA2aTransfers'),
      billPayments: require('./resources/Fsps/Payers/PayersBillPayments'),
      billSavingOffers: require('./resources/Fsps/Payers/PayersBillSavingOffers'),
      ddas: require('./resources/Fsps/Payers/PayersDDAs'),
      externalDdas: require('./resources/Fsps/Payers/PayersExternalDdas'),
      iavRequests: require('./resources/Fsps/Payers/IavRequests'),
      internalTransfers: require('./resources/Fsps/Payers/PayersInternalTransfers'),
      modelA2aTransfers: require('./resources/Fsps/Payers/PayersModelA2aTransfers'),
      modelBillPayments: require('./resources/Fsps/Payers/PayersModelBillPayments'),
      modelInternalTransfers: require('./resources/Fsps/Payers/PayersModelInternalTransfers'),
      modelP2pPayments: require('./resources/Fsps/Payers/PayersModelP2pPayments'),
      notifications: require('./resources/Fsps/Payers/PayersNotifications'),
      p2pPayments: require('./resources/Fsps/Payers/PayersP2PPayments'),
      payeeGroups: require('./resources/Fsps/Payers/PayersPayeeGroups'),
      payees: require('./resources/Fsps/Payers/PayersPayees'),
      payerPayeeGroups: require('./resources/Fsps/Payers/PayersPayerPayeeGroups'),
      recipientGroups: require('./resources/Fsps/Payers/PayersRecipientGroups'),
      recipients: require('./resources/Fsps/Payers/PayersRecipients'),
      scheduledTransactions: require('./resources/Fsps/Payers/PayersScheduledTransactions'),
      transactionHistory: require('./resources/Fsps/Payers/PayersTransactionHistory'),
      transactionHistoryTrackings: require('./resources/Fsps/Payers/PayersTransactionHistoryTrackings'),
      userAlerts: require('./resources/Fsps/Payers/PayersUserAlerts'),
      users: require('./resources/Fsps/Payers/PayersUsers'),
      venmoContacts: require('./resources/Fsps/Payers/PayersVenmoContacts'),
      venmoPayments: require('./resources/Fsps/Payers/PayersVenmoPayments'),
      checkDuplicatedTransaction: require('./resources/Fsps/Payers/checkDuplicatedTransaction'),
    }),
    payerUsers: require('./resources/Fsps/PayerUsers'),
    payerUserSettings: require('./resources/Fsps/PayerUserSettings'),
    recipientGroups: require('./resources/Fsps/RecipientGroups'),
    recipients: require('./resources/Fsps/Recipients')({
      recipientGroups: require('./resources/Fsps/Recipients/RecipientGroups'),
      userRecipients: require('./resources/Fsps/Recipients/UserRecipients'),
    }),
    rtnInfos: require('./resources/Fsps/RtnInfos'),
    sessions: require('./resources/Fsps/Sessions'),
    scheduledTransactions: require('./resources/Fsps/ScheduledTransactions')({
      checkDuplicatedTransaction: require('./resources/Fsps/ScheduledTransactions/checkDuplicatedTransaction'),
    }),
    topBillers: require('./resources/Fsps/TopBillers'),
    transactionHistory: require('./resources/Fsps/TransactionHistory'),
    transactionHistoryTrackings: require('./resources/Fsps/TransactionHistoryTrackings'),
    userAlerts: require('./resources/Fsps/UserAlerts'),
    userAutopays: require('./resources/Fsps/UserAutopays'),
    userDdas: require('./resources/Fsps/UserDdas'),
    userExternalDdas: require('./resources/Fsps/UserExternalDdas'),
    userModels: require('./resources/Fsps/UserModels'),
    userPayees: require('./resources/Fsps/UserPayees'),
    userRecipients: require('./resources/Fsps/UserRecipients'),
    userScheduledTransactions: require('./resources/Fsps/UserScheduledTransactions'),
    users: require('./resources/Fsps/Users'),
    venmoContacts: require('./resources/Fsps/VenmoContacts'),
    // endpoints
    notifyIavLoginRequired: require('./resources/Fsps/notifyIavLoginRequired'),
    resetRecipientPending: require('./resources/Fsps/resetRecipientPending'),
    validateLink: require('./resources/Fsps/validateLink'),
    validateToken: require('./resources/Fsps/validateToken'),
  }),
  eBillers: require('./resources/EBillers'),
  metrics: require('./resources/Metrics'),
  preAuthorizedCard: require('./resources/PreAuthorizedCard'),
  rtns: require('./resources/Rtns'),
  tokenizedCard: require('./resources/TokenizedCard'),
};

module.exports = resources;
