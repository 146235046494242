'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  createFilterConfig,
} = require('./commons');

const code = createFilterConfig('code', 'code', 'string');
const type = createFilterConfig('type', 'type', 'string');
const reason = createFilterConfig('reason', 'reason', 'string');

module.exports = createAddQueries({
  returnModel: {
    name: 'achcode',
  },
  finders: [
    id,
    code,
    type,
    reason,
  ],
  orderBy: [
    id,
    code,
    type,
    reason,
  ],
});
