'use strict';
const { createAddQueries } = require('../addQueries');
const { payee } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  billerId,
  deletedOnly,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/payees`,
  returnModel: {
    name: 'payee',
    model: payee,
  },
  finders: [
    id,
    userId,
    payerId,
    billerId,
    deletedOnly,
    createFilterConfig('allAttribs', 'allattribs', 'string'),
    createFilterConfig('billerAccountNumber', 'billeraccountnumber', 'string'),
    createFilterConfig('name', 'payeename', 'string'),
    createFilterConfig('nickName', 'nickname', 'string'),
    createFilterConfig('payeeStatus', 'payeestatus', 'string'),
    createFilterConfig('pendingTransaction', 'pendingtransaction', 'string'),
    createFilterConfig('elecOrCheck', 'elecorcheck', 'string'),
    createFilterConfig('rppsBillerId', 'rppsbillerid', 'id'),
  ],
  orderBy: [
    id,
    userId,
    payerId,
    billerId,
    createFilterConfig('billerAccountNumber', 'billeraccountnumber'),
    createFilterConfig('name', 'payeename'),
    createFilterConfig('nickName', 'nickname'),
    createFilterConfig('payeeStatus', 'payeestatus'),
    createFilterConfig('zip', 'zip'),
  ],
});
