'use strict';
const { createAddQueries } = require('../addQueries');
const {
  payerId,
  userId,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'userPayee',
  },
  finders: [
    payerId,
    userId,
  ],
  orderBy: [

  ],
});
