'use strict';

const { addGetOperation } = require('../../queries');
const addAutopayScheduledTransactionsQueries = require('../../queries/configs/AutopayScheduledTransactions');

function AutopayScheduledTransactions({ client, baseUrl }) {

  const AutopayScheduledTransactionsOperations = {
    returnModel: {
      name: 'autopayschedtran',
    },
    baseUrl: `${baseUrl}/autopayschedtrans`,
  };

  const AutopayScheduledTransactionsCreator = addGetOperation(client, AutopayScheduledTransactionsOperations, function AutopayScheduledTransaction(autopayScheduledTransactionId) {
    return {

    };
  });

  return Object.assign(AutopayScheduledTransactionsCreator, AutopayScheduledTransactionsOperations);
}

module.exports = addAutopayScheduledTransactionsQueries(AutopayScheduledTransactions);
