'use strict';

const { appendFilter } = require('../../../queries');
const addVenmoContactsQueries = require('../../../queries/configs/VenmoContacts');

function VenmoContacts({ client, fspId, payerId }) {
  return {
    returnModel: {
      name: 'recipient',
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/venmocontacts`,
    queriesFilter: appendFilter('payerId', payerId),

    create: function create(data, queryParams, cb) {

      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };
}

module.exports = addVenmoContactsQueries(VenmoContacts);
