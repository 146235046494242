'use strict';

const UserModels = require('./UserModels');
const { addGetOperation } = require('../../../queries');
const addPayersQueries = require('../../../queries/configs/Payers');

function Models({ client, fspId }) {

  const ModelsOperations = {
    returnModel: {
      name: 'model',
    },
    baseUrl: `/fsps/${fspId}/models`,

  };

  const ModelCreator = addGetOperation(client, ModelsOperations, function Payer(modelId) {
    return {
      userModels: new UserModels({ client, fspId, modelId }),

    };
  });

  return Object.assign(ModelCreator, ModelsOperations);
}

module.exports = addPayersQueries(Models);
