'use strict';

const environments = {
  production: {
    host: 'https://api.payrailzprod.com',
  },
  sandbox: {
    host: 'https://sandboxapi.payrailztest.com',
  },
  sandbox2: {
    host: 'https://sandbox2api.payrailztest.com',
  },
  development: {
    host: 'https://dev1api.payrailzdev.com',
  },
  'development-internal': {
    host: 'https://dev1api-int.payrailzdev.com',
  },
  development2: {
    host: 'https://dev2api.payrailzdev.com',
  },
  'development2-internal': {
    host: 'https://dev2api-int.payrailzdev.com',
  },
  development3: {
    host: 'https://dev3api.payrailzdev.com',
  },
  'development3-internal': {
    host: 'https://dev3api-int.payrailzdev.com',
  },
  qa1: {
    host: 'https://qa1api.payrailzdev.com',
  },
  'qa1-internal': {
    host: 'https://qa1api-int.payrailzdev.com',
  },
  qa2: {
    host: 'https://qa2api.payrailzdev.com',
  },
  'qa2-internal': {
    host: 'https://qa2api-int.payrailzdev.com',
  },
  imp1: {
    host: 'https://imp1api.payrailztest.com',
  },
  'imp1-internal': {
    host: 'https://imp1api-int.payrailztest.com',
  },
  perf: {
    host: 'https://perfapi.payrailzdev.com',
  },
  training: {
    host: 'https://trainingapi.payrailztest.com',
  },
  conv: {
    host: 'https://convapi.payrailztest.com',
  },
  dr: {
    host: 'https://drapi.payrailzdr.com',
  },
  uat1: {
    host: 'https://uat1api.payrailztest.com',
  },
  local: {
    host: 'http://localhost:8000',
  },
};

module.exports = environments;
