'use strict';

const { addGetOperation } = require('../queries');
const addAchCodesQueries = require('../queries/configs/AchCodes');

function AchCodes({ client }) {

  const AchCodesOperations = {
    baseUrl: '/achcodes',
    returnModel: {
      name: 'achcode',
    },

  };

  const AchCodesCreator = addGetOperation(client, AchCodesOperations, function ResearchReason(id) {
    return {
    };
  });

  return Object.assign(AchCodesCreator, AchCodesOperations);
}

module.exports = addAchCodesQueries(AchCodes);
