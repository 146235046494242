'use strict';

const { p2pPayment } = require('../../../datatypes');
const { appendFilter } = require('../../../queries');
const addP2pPaymentsQueries = require('../../../queries/configs/P2pPayments');

function P2PPayments({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'p2ppayment',
      model: p2pPayment,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/p2ppayments`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addP2pPaymentsQueries(P2PPayments);
