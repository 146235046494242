'use strict';

const { addGetOperation } = require('../../queries');
const addUserPayeesQueries = require('../../queries/configs/UserPayees');

function UserPayees({ client, fspId }) {

  const UserPayeesOperations = {
    returnModel: {
      name: 'userPayee',
    },
    baseUrl: `/fsps/${fspId}/userpayees`,

  };

  const UserPayeesCreator = addGetOperation(client, UserPayeesOperations, function UserPayee(userPayeeId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${UserPayeesOperations.baseUrl}/${userPayeeId}`, {
          data,
          queryParams,
          cb,
          returnModel: UserPayeesOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${UserPayeesOperations.baseUrl}/${userPayeeId}`, {
          queryParams,
          cb,
          returnModel: UserPayeesOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(UserPayeesCreator, UserPayeesOperations);
}

module.exports = addUserPayeesQueries(UserPayees);
