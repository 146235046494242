'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'venmocontact',
  },
  finders: [
    id,
    payerId,
    userId,
  ],
  orderBy: [
  ],
});
