'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/payeeebills`,
  returnModel: {
    name: 'payeeebill',
  },
  finders: [
    id,
    payerId,
    userId,
    createFilterConfig('ebillerBillId', 'ebillerbillid', 'id'),
    createFilterConfig('ebillerAccountId', 'ebilleraccountid', 'id'),
  ],
  orderBy: [
    id,
    payerId,
    userId,
  ],
});
