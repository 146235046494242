'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  recipientId,
  payeeId,
  externalDdaId,
  scheduledTransactionId,
  createFilterConfig,
  createdAt,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/notifications`,
  returnModel: {
    name: 'notification',
  },
  finders: [
    id,
    payerId,
    userId,
    recipientId,
    payeeId,
    externalDdaId,
    scheduledTransactionId,
    createdAt,
    createFilterConfig('insertedAt', 'createdat', 'date'),
  ],
  orderBy: [
    id,
    payerId,
    userId,
    recipientId,
    payeeId,
    externalDdaId,
    scheduledTransactionId,
    createdAt,
    createFilterConfig('messageStatus', 'msgstatus', 'string'),
    createFilterConfig('deliverMethod', 'delivermethod', 'string'),
    createFilterConfig('insertedAt', 'createdat'),
  ],
});
