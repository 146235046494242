'use strict';


function VenmoPayments({ client, baseUrl, payerId }) {
  return {
    returnModel: {
      name: 'p2ppayment',
    },
    baseUrl: `${baseUrl}/${payerId}/venmopayments`,

    create: function create(data, queryParams, cb) {

      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };
}

module.exports = VenmoPayments;
