'use strict';

const { addGetOperation } = require('../../queries');
const addImportantMessagesQueries = require('../../queries/configs/ImportantMessages');

function ImportantMessages({ client, fspId }) {

  const ImportantMessagesOperations = {
    returnModel: {
      name: 'importantmessage',
    },
    baseUrl: `/fsps/${fspId}/importantmessages`,
  };

  const ImportantMessagesCreator = addGetOperation(client, ImportantMessagesOperations, function ImportantMessage(importantMessageId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${ImportantMessagesOperations.baseUrl}/${importantMessageId}`, {
          data,
          queryParams,
          cb,
          returnModel: ImportantMessagesOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(ImportantMessagesCreator, ImportantMessagesOperations);
}

module.exports = addImportantMessagesQueries(ImportantMessages);
