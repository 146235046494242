'use strict';

const { addGetOperation } = require('../../../queries');
const addDdasQueries = require('../../../queries/configs/Ddas');
const { decryptField } = require('../../../queries/utils');
const { createResources } = require('../../../utils');

const Ddas = (subResources) => function ({ client, fspId }) {

  const DdasOperations = {
    returnModel: {
      name: 'dda',
    },
    baseUrl: `/fsps/${fspId}/ddas`,

  };

  const DdasCreator = addGetOperation(client, DdasOperations,
    function Dda(ddaId, { deletedOnly } = {}) {
      const config = {
        client,
        baseUrl: DdasOperations.baseUrl,
        fspId,
        ddaId,
      };
      return Object.assign(
        {},
        createResources(subResources, config),
        {
          updateStatus: function (data, queryParams, cb) {
            return client.executeRequest('POST', `${DdasOperations.baseUrl}/${ddaId}`, {
              data,
              queryParams,
              cb,
              returnModel: DdasOperations.returnModel,
            });
          },

          decryptFspDdaId: (cb) =>
            decryptField({
              client,
              id: ddaId,
              fieldName: 'fspDdaId',
              resourceDefinition: DdasOperations,
              queryParams: {
                deletedOnly,
              },
              cb,
            }),

          decryptAccountNumber: (cb) =>
            decryptField({
              client,
              id: ddaId,
              fieldName: 'accountNbr',
              resourceDefinition: DdasOperations,
              queryParams: {
                deletedOnly,
              },
              cb,
            }),

          decryptAccountMicrNumber: (cb) =>
            decryptField({
              client,
              id: ddaId,
              fieldName: 'accountMicrNbr',
              resourceDefinition: DdasOperations,
              queryParams: {
                deletedOnly,
              },
              cb,
            }),

          decryptAccountAchNumber: (cb) =>
            decryptField({
              client,
              id: ddaId,
              fieldName: 'accountAchNbr',
              resourceDefinition: DdasOperations,
              queryParams: {
                deletedOnly,
              },
              cb,
            }),
        },
      );
    },
  );

  return Object.assign(DdasCreator, DdasOperations);
}

module.exports = (subResources) => addDdasQueries(Ddas(subResources));
