'use strict';

const addUsersQueries = require('../../../queries/configs/Users');

function Users({ payerId, baseUrl }) {
  return {
    returnModel: {
      name: 'user',
    },
    baseUrl: `${baseUrl}/${payerId}/users`,
  };
}

module.exports = addUsersQueries(Users);
