const utils = require('../../utils');

class BrowserClientProvider {
  constructor(authHost, extraRequestHttpOptions = {}) {
    this.authHost = authHost;
    this.extraRequestHttpOptions = extraRequestHttpOptions;
    this.requestInProgress = null;
  }

  async safeGet(client) {
    if (this.requestInProgress) {
      return this.requestInProgress;
    }
    this.requestInProgress = client.invoke('post', '/createAccessToken', {
      httpOptions: {
        host: this.authHost,
        Authorization: 'none',
      },
      extraRequestHttpOptions: this.extraRequestHttpOptions,
    });

    this.requestInProgress.finally(() => {
      this.requestInProgress = null
    });
    return this.requestInProgress;
  }

  async get(client) {
    if (this.token && !utils.tokenHasExpired(this.token)) {
      return this.token;
    }
    const { token, environment } = await this.safeGet(client);
    this.environment = environment;
    this.token = token;
    return token;
  }

  clearCache() {
    this.token = null;
  }
}

module.exports = BrowserClientProvider;
