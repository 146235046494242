'use strict';

function RecipientGroups({ client, baseUrl, recipientId }) {

  return {
    returnModel: {
      name: 'recipientgroups',
    },
    baseUrl: `${baseUrl}/${recipientId}/recipientgroups`,

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = RecipientGroups;
