'use strict';

const { addGetOperation } = require('../../../queries');
const addTransactionHistoryTrackingsQueries = require('../../../queries/configs/TransactionHistoryTrackings');

function TransactionHistoryTrackings({ client, fspId, payerId }) {

  const TransactionHistoryTrackingsOperations = {
    returnModel: {
      name: 'histtrantrackings',
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/histtrantrackings`,
  };

  return addGetOperation(
    client,
    TransactionHistoryTrackingsOperations,
    function (transactionHistoryTrackingsId) {
      return addTransactionHistoryTrackingsQueries(function TransactionHistoryTracking() {
        return {
        }
      })({ client, fspId, transactionHistoryTrackingsId });
    },
  );
}

module.exports = TransactionHistoryTrackings;
