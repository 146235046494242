'use strict';

const { addGetOperation } = require('../../queries');
const addSessionsQueries = require('../../queries/configs/Sessions');

function Sessions({ client, fspId }) {

  const SessionsOperations = {
    returnModel: {
      name: 'sessions',
    },
    baseUrl: `/fsps/${fspId}/sessions`,
    validateSsoToken: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: { name: 'sso' },
      });
    },
  };

  const SessionsCreator = addGetOperation(client, SessionsOperations, function Session(sessionId) {
    return {
      refresh: function (data, cb) {
        return client.executeRequest('POST', `${SessionsOperations.baseUrl}/${sessionId}/refresh`, {
          data,
          cb,
          returnModel: SessionsOperations.returnModel,
        });
      },

      close: function (cb) {
        return client.executeRequest('POST', `${SessionsOperations.baseUrl}/${sessionId}`, {
          cb,
          returnModel: SessionsOperations.returnModel,
        });
      },

      update: function (data, cb) {
        return client.executeRequest('POST', `${SessionsOperations.baseUrl}/${sessionId}/cache`, {
          data,
          cb,
          returnModel: SessionsOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(SessionsCreator, SessionsOperations);
}

module.exports = addSessionsQueries(Sessions);
