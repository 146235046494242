'use strict';

function EbillAutopays({ client, payeeId, baseUrl }) {
  return {
    returnModel: {
      name: 'ebillautopay',
    },
    baseUrl: `${baseUrl}/${payeeId}/ebillautopays`,

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },

  };
}

module.exports = EbillAutopays;
