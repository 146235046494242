'use strict';

const { addGetOperation } = require('../../../queries');
const addPayeesQueries = require('../../../queries/configs/Payees');
const { payee } = require('../../../datatypes');
const { decryptField } = require('../../../queries/utils');
const { createResources } = require('../../../utils');
const PayeeGroups = require('./PayeeGroups');
const PayeeEbills = require('./PayeeEbills');
const UserPayees = require('./UserPayees');

const Payees = (subResources) => function ({ client, fspId }) {

  const PayeesOperations = {
    returnModel: {
      name: 'payee',
      model: payee,
    },
    baseUrl: `/fsps/${fspId}/payees`,

  };

  const PayeesCreator = addGetOperation(client, PayeesOperations,
    function Payee(payeeId, { deletedOnly } = {}) {
      const config = {
        client,
        baseUrl: PayeesOperations.baseUrl,
        fspId,
        payeeId,
      };
      return Object.assign(
        {},
        createResources(subResources, config),
        {
          payeeGroups: new PayeeGroups({ client, fspId, payeeId }),
          payeeEbills: new PayeeEbills({ client, fspId, payeeId }),
          userPayees: new UserPayees({ client, fspId, payeeId }),
          delete: function (queryParams, cb) {
            return client.executeRequest('DELETE', `${PayeesOperations.baseUrl}/${payeeId}`, {
              queryParams,
              cb,
              returnModel: PayeesOperations.returnModel,
            });
          },
          update: function (data, queryParams, cb) {
            return client.executeRequest('POST', `${PayeesOperations.baseUrl}/${payeeId}`, {
              data,
              queryParams,
              cb,
              returnModel: PayeesOperations.returnModel,
            });
          },
          enrollPayeeEbillers: function (data, cb) {
            return client.executeRequest('POST', `${PayeesOperations.baseUrl}/${payeeId}/enrollpayeeebillers`, {
              data,
              cb,
              returnModel: PayeesOperations.returnModel,
            });
          },
          unEnrollPayeeEbillers: function (cb) {
            return client.executeRequest('POST', `${PayeesOperations.baseUrl}/${payeeId}/unenrollpayeeebillers`, {
              cb,
              returnModel: PayeesOperations.returnModel,
            });
          },
          decryptAccountNumber: (cb) =>
            decryptField({
              client,
              id: payeeId,
              fieldName: 'billerAccountNumber',
              resourceDefinition: PayeesOperations,
              queryParams: {
                deletedOnly,
              },
              cb,
            }),
        });
    });

  return Object.assign(PayeesCreator, PayeesOperations);
}

module.exports = (subResources) => addPayeesQueries(Payees(subResources));
