'use strict';

function UserScheduledTransactions({ client, fspId, scheduledTransactionId }) {

  return {
    returnModel: {
      name: 'userSchedTran',
    },
    baseUrl: `/fsps/${fspId}/schedtrans/${scheduledTransactionId}/userschedtrans`,

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = UserScheduledTransactions;
