'use strict';

function Metrics({ client }) {
  return {
    baseUrl: '/metrics',

    create: function create(data, cb) {
      return client.addMetrics(data, cb);
    },
  };
}

module.exports = Metrics;
