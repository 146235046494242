'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'payerebillernotify',
  },
  finders: [
    id,
    payerId,
    userId,
    createFilterConfig('netUserAccountIdStr', 'netuseraccountidstr', 'string'),
    createFilterConfig('netUserIdStr', 'netuseridstr', 'string'),
    createFilterConfig('payerEbillernetEbillerId', 'payerebillernetebillerid', 'id'),
  ],
  orderBy: [
    id,
    createFilterConfig('netUserAccountIdStr', 'netuseraccountidstr', 'string'),
    createFilterConfig('netUserIdStr', 'netuseridstr', 'string'),
    createFilterConfig('payerEbillernetEbillerId', 'payerebillernetebillerid', 'id'),
  ],
});
