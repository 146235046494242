'use strict';

const { addGetOperation } = require('../../queries');
const addRecipientGroupsQueries = require('../../queries/configs/RecipientGroups');

function RecipientGroups({ client, baseUrl }) {

  const RecipientGroupsOperations = {
    returnModel: {
      name: 'recipientgroups',
    },
    baseUrl: `${baseUrl}/recipientgroups`,

  };

  const RecipientGroupsCreator = addGetOperation(client, RecipientGroupsOperations, function RecipientGroup(recipientGroupId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${RecipientGroupsOperations.baseUrl}/${recipientGroupId}`, {
          data,
          queryParams,
          cb,
          returnModel: RecipientGroupsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${RecipientGroupsOperations.baseUrl}/${recipientGroupId}`, {
          queryParams,
          cb,
          returnModel: RecipientGroupsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(RecipientGroupsCreator, RecipientGroupsOperations);
}

module.exports = addRecipientGroupsQueries(RecipientGroups);
