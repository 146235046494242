'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  ddaId,
  payeeId,
  scheduledTransactionId,
  createFilterConfig,
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/autopayschedtrans`,
  returnModel: {
    name: 'autopayschedtran',
  },
  finders: [
    id,
    payerId,
    userId,
    ddaId,
    payeeId,
    scheduledTransactionId,
    createFilterConfig('autopayId', 'autopayid', 'id'),
    createFilterConfig('autopayYear', 'autopayyear', 'string'),
    createFilterConfig('autopayMonth', 'autopaymonth', 'string'),
    createFilterConfig('ddaLoanStatementId', 'ddaloanstmtid', 'id'),
    createFilterConfig('transactionType', 'transactiontype', 'string'),
  ],
  orderBy: [
    id,
    payerId,
    userId,
    payeeId,
    scheduledTransactionId,
    createFilterConfig('autopayId', 'autopayid', 'id'),
    createFilterConfig('autopayYear', 'autopayyear', 'string'),
    createFilterConfig('autopayMonth', 'autopaymonth', 'string'),
    createFilterConfig('ddaLoanStatementId', 'ddaloanstmtid', 'id'),
    createFilterConfig('transactionType', 'transactiontype', 'string'),
  ],
});
