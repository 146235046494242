'use strict';

const { addGetOperation } = require('../queries');
const addRtnsQueries = require('../queries/configs/Rtns');

function Rtns({ client }) {

  const RtnsOperations = {
    baseUrl: '/rtns',
    returnModel: {
      name: 'rtn',
    },

    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

  const RtnsCreator = addGetOperation(client, RtnsOperations, function ResearchReason(rtnId) {
    return {

      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${RtnsOperations.baseUrl}/${rtnId}`, {
          data,
          queryParams,
          cb,
          returnModel: RtnsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${RtnsOperations.baseUrl}/${rtnId}`, {
          queryParams,
          cb,
          returnModel: RtnsOperations.returnModel,
        });
      },
    };
  });

  return Object.assign(RtnsCreator, RtnsOperations);
}

module.exports = addRtnsQueries(Rtns);
