'use strict';

const { appendFilter } = require('../../../queries');
const addModelBillPaymentsQueries = require('../../../queries/configs/ModelBillPayments');
const { modelBillPayment } = require('../../../datatypes');

function ModelBillPayments({ client, fspId, payerId }) {

  return {
    returnModel: {
      name: 'modelbillpayment',
      model: modelBillPayment,
    },
    baseUrl: `/fsps/${fspId}/payers/${payerId}/modelbillpayments`,
    queriesFilter: appendFilter('payerId', payerId),
    create: function create(data, queryParams, cb) {
      return client.executeRequest('POST', this.baseUrl, {
        data,
        queryParams,
        cb,
        returnModel: this.returnModel,
      });
    },
  };

}

module.exports = addModelBillPaymentsQueries(ModelBillPayments);
