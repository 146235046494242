const differenceInSeconds = require('date-fns/differenceInSeconds');
const startOfMonth = require('date-fns/startOfMonth');
const endOfMonth = require('date-fns/endOfMonth');
const format = require('date-fns/format');
const parseISO = require('date-fns/parseISO');
const { type } = require('ramda');

const undefinedIfEmpty = (array) => array.length > 0 ? array : undefined;

module.exports = {
  getDefaultHost: function (env) {
    const pzEnv = require('./pzEnv');
    let resolvedEnv = (typeof env === 'string' && (env in pzEnv)) ? env : 'sandbox';
    return pzEnv[resolvedEnv].host;
  },

  capitalize: (string) => string[0].toUpperCase() + string.substring(1),

  createResources: (resources, resourcesParameters) =>
    Object.keys(resources).reduce((obj, key) => Object.assign(
      {},
      obj,
      { [key]: new resources[key](resourcesParameters) },
    ),
      {}),

  tokenHasExpired: function (token) {
    const elapsed = differenceInSeconds(new Date(), new Date(token.createdAt));
    const leewayInSecs = 30;
    return elapsed >= token.accessTokenExpiresInSecs - leewayInSecs;
  },

  getMonthEdgeDates: function (month, year) {
    const monthString = month.toString().padStart(2, '0');
    const dayInMonth = `${year}-${monthString}-10`;
    const formatDate = (aDate) => format(aDate, 'yyyy-MM-dd');
    return [
      startOfMonth(parseISO(dayInMonth)),
      endOfMonth(parseISO(dayInMonth)),
    ].map(formatDate);
  },

  adaptPromiseThen: function (f, cb = (error, data) => null) {
    return function (error, data) {
      try {
        cb(null, f(data));
      }
      catch (e) {
        cb(e, null);
      }
    };
  },
  adaptPromiseCatch: function (f, cb = (error, data) => null) {
    return function (error, data) {
      if (!error) {
        return cb(error, data);
      }
      try {
        cb(null, f(error));
      }
      catch (e) {
        cb(e, null);
      }
    };
  },
  handleNoElements: res => {
    if (res && res.statusCode === 404) {
      return [];
    }
    throw res;
  },
  removeKey: (object, key) => {
    const newObject = Object.assign({}, object);
    delete newObject[key];
    return newObject;
  },
  noLogger: {
    error: () => null,
    warn: () => null,
    info: () => null,
    verbose: () => null,
    debug: () => null,
    silly: () => null,
  },
  inferCallbackArgument: (argsArray) => {
    if (!argsArray) {
      return {};
    }

    const lastIndex = Math.max(argsArray.length - 1, 0);
    const lastArgument = argsArray[lastIndex];

    const cb = type(lastArgument) === 'Function' ? lastArgument : undefined;

    const restOfArguments = undefinedIfEmpty(
      cb ? argsArray.slice(0, -1) : argsArray,
    );
    return { restOfArguments, cb };
  },
};
