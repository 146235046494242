'use strict';
const { createAddQueries } = require('../addQueries');
const { modelA2aTransfer } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  amount,
  microService,
  transaction: {
    sendOn,
    deliver,
    deliverOn,
    status,
    foreverOffOn,
    frequency,
  },
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/modela2atransfers`,
  returnModel: {
    name: 'modela2atransfer',
    model: modelA2aTransfer,
  },
  finders: [
    id,
    userId,
    payerId,
    status,
    sendOn,
    deliver,
    deliverOn,
    microService,
    amount,
    foreverOffOn,
    frequency,
  ],
  orderBy: [
    id,
    payerId,
    status,
    sendOn,
    microService,
    deliverOn,
    amount,
    deliver,
  ],
});
