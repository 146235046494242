'use strict';

const { addGetOperation } = require('../../queries');
const addUserScheduledTransactionsQueries = require('../../queries/configs/UserScheduledTransactions');

function UserScheduledTransactions({ client, fspId }) {

  const UserScheduledTransactionsOperations = {
    returnModel: {
      name: 'userSchedTran',
    },
    baseUrl: `/fsps/${fspId}/userschedtrans`,
  };

  const UserScheduledTransactionsCreator = addGetOperation(client, UserScheduledTransactionsOperations, function (userScheduledTransactionId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${UserScheduledTransactionsOperations.baseUrl}/${userScheduledTransactionId}`, {
          data,
          queryParams,
          cb,
          returnModel: UserScheduledTransactionsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${UserScheduledTransactionsOperations.baseUrl}/${userScheduledTransactionId}`, {
          queryParams,
          cb,
          returnModel: UserScheduledTransactionsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(UserScheduledTransactionsCreator, UserScheduledTransactionsOperations);
}

module.exports = addUserScheduledTransactionsQueries(UserScheduledTransactions);
