'use strict';

function NoAccessTokenProvider() {
  return {
    get: () => ({}),
    clearCache: () => {
      // No need to clear cache
    },
  };
}

module.exports = NoAccessTokenProvider;
