'use strict';
const { createAddQueries } = require('../addQueries');
const { billPayment } = require('../../datatypes');
const {
  id,
  payerId,
  userId,
  modelId,
  payeeId,
  amount,
  transaction: {
    transactionType,
    sendOn,
    deliver,
    deliverOn,
    status,
    frequency,
    foreverOffOn,
    confirmationNumber,
    ipv4,
  },
} = require('./commons');


module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/billpayments`,
  returnModel: {
    name: 'billpayment',
    model: billPayment,
  },
  finders: [
    id,
    payerId,
    userId,
    payeeId,
    modelId,
    status,
    transactionType,
    amount,
    sendOn,
    deliver,
    deliverOn,
    frequency,
    foreverOffOn,
    confirmationNumber,
    ipv4,
  ],
  orderBy: [
    id,
    payerId,
    modelId,
    payeeId,
    status,
    sendOn,
    foreverOffOn,
    deliverOn,
    amount,
    deliver,
    frequency,
    transactionType,
    confirmationNumber,
    ipv4,
  ],
});
