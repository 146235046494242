const { urlLink } = require('../../datatypes');

module.exports = function validateLink({ client, fspId }) {
  return function (linkId, cb) {
    return client.executeRequest('POST', `/fsps/${fspId}/urllink`, {
      cb,
      queryParams: { link: linkId },
      returnModel: {
        name: 'urllink',
        model: urlLink,
      },
    });
  };
}