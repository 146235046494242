'use strict';

const UserAutopays = require('./UserAutopays');
const AutopayScheduledTransactions = require('./AutopayScheduledTransactions');

function Autopays({ client, fspId }) {

  const baseUrl = `/fsps/${fspId}/autopays`;

  return function FspPayerType(autopayId) {
    return {
      autopayScheduledTransactions: AutopayScheduledTransactions({ client, baseUrl, fspId, autopayId }),
      userAutopays: UserAutopays({ client, baseUrl, fspId, autopayId }),
    };
  };
}

module.exports = Autopays;
