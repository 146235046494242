'use strict';

// This resource is deprecated, it is only here until SSO updates some tests that use
// this resource (although this resource is not used in the application)
function EBillers({ client }) {

  const EBillersOperations = {
    baseUrl: '/ebillers',
    returnModel: {
      name: 'biller',
    },

    query: function (generatorOrOperations, cb) {

      const filters = generatorOrOperations({
        type: (billerType) => ({ field: 'type', value: billerType }),
        nameLike: (value) => ({ field: 'name', value }),
      });
      return client.executeRequest('POST', '/finovera/ebillers/query', {
        data: { filters },
        cb,
        httpOptions: {
          host: client.getKey('ebillers.host'),
        },
      });
    },
  };

  const EBillersCreator = function (eBillerId) {
    return {
      get: async function (cb) {
        const objects = await client.executeRequest('POST', '/finovera/ebillers/query', {
          data: {
            filters: [
              { field: 'id', value: eBillerId },
            ],
          },
          cb,
          httpOptions: {
            host: client.getKey('ebillers.host'),
          },
        });

        return objects[0];
      },

      startLogin: function (data, cb) {
        return client.executeRequest('POST', `/finovera/ebillers/${eBillerId}/startLogin`, {
          data,
          cb,
          httpOptions: {
            host: client.getKey('ebillers.host'),
          },
        });
      },

      getLoginStatus: function (data, cb) {
        return client.executeRequest('POST', `/finovera/ebillers/${eBillerId}/getLoginStatus`, {
          data,
          cb,
          httpOptions: {
            host: client.getKey('ebillers.host'),
          },
        });
      },

      setLoginMFAAnswers: function (data, cb) {
        return client.executeRequest('POST', `/finovera/ebillers/${eBillerId}/setLoginMFAAnswers`, {
          data,
          cb,
          httpOptions: {
            host: client.getKey('ebillers.host'),
          },
        });
      },

      getBillsInfo: function (data, cb) {
        return client.executeRequest('POST', `/finovera/ebillers/${eBillerId}/getBillsInfo`, {
          data,
          cb,
          httpOptions: {
            host: client.getKey('ebillers.host'),
          },
        });
      },
    };
  };

  return Object.assign(EBillersCreator, EBillersOperations);
}

module.exports = EBillers;
