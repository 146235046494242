'use strict';
const { createAddQueries } = require('../addQueries');
const { id, createFilterConfig } = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'biller',
  },
  finders: [
    id,
    createFilterConfig('billerLogoId', 'billerlogoid', 'id'),
    createFilterConfig('billerNetId', 'billernetid', 'id'),
    createFilterConfig('billerSetId', 'billersetid', 'id'),
    createFilterConfig('billerStatus', 'billerstatus', 'string'),
    createFilterConfig('billerTypeId', 'billertypeid', 'id'),
    createFilterConfig('name', 'name', 'string'),
    createFilterConfig('netIdStr', 'netidstr', 'string'),
    createFilterConfig('rppsBillerId', 'rppsbillerid', 'id'),
  ],
  orderBy: [
    id,
    'billerLogoId',
    'billerNetId',
    'billerTypeId',
    'name',
    'netIdStr',
    'rppsBillerId',
  ],
});
