'use strict';
const { createAddQueries } = require('../addQueries');
const {
  id,
  payerId,
  userId,
  ddaId,
  payeeId,
} = require('./commons');

module.exports = createAddQueries({
  returnModel: {
    name: 'ebillautopay',
  },
  finders: [
    id,
    payerId,
    userId,
    ddaId,
    payeeId,
  ],
  orderBy: [
    id,
    payerId,
    userId,
    payeeId,
  ],
});
