
const createFilterConfig = (name, queryParamName, type) => ({ name, queryParamName, type });

module.exports = {
  createFilterConfig,
  id: createFilterConfig('id', 'id', 'id'),
  userId: createFilterConfig('userId', 'userid', 'id'),
  payerId: createFilterConfig('payerId', 'payerid', 'id'),
  payeeId: createFilterConfig('payeeId', 'payeeid', 'id'),
  fspId: createFilterConfig('fspId', 'fspId', 'id'),
  recipientId: createFilterConfig('recipientId', 'recipientid', 'id'),
  billerId: createFilterConfig('billerId', 'billerid', 'id'),
  channelId: createFilterConfig('channelId', 'channelid', 'id'),
  ddaId: createFilterConfig('ddaId', 'ddaid', 'id'),
  transferToDdaId: createFilterConfig('transferToDdaId', 'transfertoddaid', 'id'),
  externalDdaId: createFilterConfig('externalDdaId', 'externalddaid', 'id'),
  scheduledTransactionId: createFilterConfig('scheduledTransactionId', 'schedid', 'id'),
  fspPayerId: createFilterConfig('fspPayerId', 'fsppayerid', 'id'),
  modelId: createFilterConfig('modelId', 'modelid', 'id'),
  microService: createFilterConfig('microService', 'microservice', 'string'),
  amount: createFilterConfig('amount', 'amount', 'amount'),
  createdAt: createFilterConfig('createdAt', 'createdat', 'date'),
  updatedAt: createFilterConfig('updatedAt', 'updatedat', 'date'),
  deletedOnly: createFilterConfig('deletedOnly', 'deletedonly', 'string'),
  transaction: {
    transactionType: createFilterConfig('transactionType', 'transactionType', 'string'),
    sendOn: createFilterConfig('sendOn', 'sendon', 'date'),
    deliver: createFilterConfig('deliver', 'deliver', 'string'),
    deliverOn: createFilterConfig('deliverOn', 'deliveron', 'date'),
    status: createFilterConfig('status', 'status', 'string'),
    foreverOffOn: createFilterConfig('foreverOffOn', 'foreveroffon', 'string'),
    frequency: createFilterConfig('frequency', 'frequency', 'string'),
    confirmationNumber: createFilterConfig('confirmationNumber', 'confirmnbr', 'string'),
    transferToDdaId: createFilterConfig('transferToDdaId', 'transfertoddaid', 'id'),
    transferFromDdaId: createFilterConfig('transferFromDdaId', 'transferfromddaid', 'id'),
    ipv4: createFilterConfig('ipv4', 'ipv4', 'string'),
  },
};
