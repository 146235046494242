'use strict';

const { addGetOperation } = require('../../queries');
const addUserAlertsQueries = require('../../queries/configs/UserAlerts');

function UserAlerts({ client, fspId }) {

  const UserAlertsOperations = {
    returnModel: {
      name: 'useralerts',
    },
    baseUrl: `/fsps/${fspId}/useralerts`,

  };

  const UserAlertsCreator = addGetOperation(client, UserAlertsOperations, function UserAlert(ddaId) {
    return {
      update: function (data, queryParams, cb) {
        return client.executeRequest('POST', `${UserAlertsOperations.baseUrl}/${ddaId}`, {
          data,
          queryParams,
          cb,
          returnModel: UserAlertsOperations.returnModel,
        });
      },
      delete: function (queryParams, cb) {
        return client.executeRequest('DELETE', `${UserAlertsOperations.baseUrl}/${ddaId}`, {
          queryParams,
          cb,
          returnModel: UserAlertsOperations.returnModel,
        });
      },

    };
  });

  return Object.assign(UserAlertsCreator, UserAlertsOperations);
}

module.exports = addUserAlertsQueries(UserAlerts);
