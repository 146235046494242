'use strict';

const { addGetOperation } = require('../../queries');
const addPayerEbillerAccountsQueries = require('../../queries/configs/PayerEbillerAccounts');

function PayerEbillerAccounts({ client, fspId }) {

  const PayerEbillerAccountsOperations = {
    returnModel: {
      name: 'payerebilleraccount',
    },
    baseUrl: `/fsps/${fspId}/payerebilleraccounts`,

  };

  const PayerEbillerAccountsCreator = addGetOperation(client, PayerEbillerAccountsOperations,
    function (id) {
      return {
      };
    },
  );

  return Object.assign(PayerEbillerAccountsCreator, PayerEbillerAccountsOperations);
}

module.exports = addPayerEbillerAccountsQueries(PayerEbillerAccounts);
