module.exports = function checkDuplicatedTransaction({ client, baseUrl }) {
  return function (data, cb) {
    return client.executeRequest('POST', `${baseUrl}/duplicatetranscheck`, {
      cb,
      data,
      returnModel: {
        name: 'duplicatetranscheck',
      },
    });
  };
}