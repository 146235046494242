'use strict';
const { createAddQueries } = require('../addQueries');
const { internalTransfer } = require('../../datatypes');

const {
  id,
  payerId,
  userId,
  modelId,
  amount,
  transaction: {
    sendOn,
    confirmationNumber,
    foreverOffOn,
    frequency,
    transferToDdaId,
    transferFromDdaId,
    ipv4,
  },
} = require('./commons');

module.exports = createAddQueries({
  baseUrl: ({ fspId }) => `/fsps/${fspId}/internaltransfers`,
  returnModel: {
    name: 'internaltransfer',
    model: internalTransfer,
  },
  finders: [
    id,
    userId,
    payerId,
    transferToDdaId,
    transferFromDdaId,
    sendOn,
    amount,
    modelId,
    confirmationNumber,
    foreverOffOn,
    frequency,
    ipv4,
  ],
  orderBy: [
    id,
    amount,
    payerId,
    modelId,
    foreverOffOn,
    frequency,
    confirmationNumber,
    transferToDdaId,
    transferFromDdaId,
    sendOn,
    ipv4,
  ],
});
