'use strict';

const qs = require('qs');
const { mapObjIndexed, mergeDeepRight, type, identity, prop, reduce, pickBy, pathOr } = require('ramda');
const { blacklistFields } = require('./transformations');
const utils = require('../utils');

const measureRequest = () => {
  const ts = Date.now();

  const measureSuccess = (response) => {
    response.duration = Date.now() - ts;
    return response;
  };

  const measureError = (error) => {
    error.duration = Date.now() - ts;
    throw error;
  };

  return [measureSuccess, measureError];
};

function transformData(data, model) {
  if (!model) {
    return data;
  }

  const nullSafe = (item, wrappedFunction) => item ? wrappedFunction(item) : null;

  const removeUndefinedKeys = pickBy(v => v !== undefined);

  const populateSingleItem = (item) => removeUndefinedKeys(mapObjIndexed((v, k) => {
    if (k.startsWith('_')) {
      return undefined;
    }
    switch (type(v)) {
      case 'Object': return transformData(item[k], v);
      case 'Function': return v(item[k]);
      default: return item[k];
    }
  }, model));

  const transformSingleItem = (item) => nullSafe(item, populateSingleItem);

  if (type(data) === 'Array') {
    return data.map(transformSingleItem);
  }

  return transformSingleItem(data);
}

const createTransformDefinition = (returnModel) => {
  const generalBlacklistedFields = blacklistFields([ 'href' ]);

  const defaultDefinition = {
    extractData: identity,
    model: {
      _transformations: [ generalBlacklistedFields ],
    },
  };

  if (returnModel) {
    defaultDefinition.extractData = prop(returnModel.name);
    const { model } = returnModel;
    if (model) {
      defaultDefinition.model = Object.assign(
        {},
        model,
        {
          _transformations: defaultDefinition.model._transformations.concat(model._transformations || []),
        },
      );
    }
  }
  return defaultDefinition;
};

const transformNestedFields = (object, model) => {

  const removeUndefinedKeys = pickBy((v, k) => v !== undefined && !k.startsWith('_'));

  return removeUndefinedKeys(mapObjIndexed((v, k) => {
    if (type(model[k]) === 'Object') {
      const returnModel = createTransformDefinition({ model: model[k] })
      return applyModelTransformation(v, { model: returnModel.model });
    }

    return v;
  }, object));

}

const applyModelTransformation = (object, { model }) => {
  if (type(object) === 'Array') {
    return object.map(o => applyModelTransformation(o, { model }));
  }
  if (type(object) !== 'Object') {
    return object;
  }

  const transformations = model._transformations;

  const transformedObject = reduce(
    (transformed, transform) => transform(transformed),
    object,
    transformations,
  );

  return transformNestedFields(transformedObject, model);
};

function paramsSerializer(queryParams) {
  return qs.stringify(queryParams, { arrayFormat: 'brackets' });
}


module.exports = (accessTokenProvider, mayHttpClient) => {
  let httpClient = mayHttpClient;
  if (!httpClient) {
    httpClient = require('./httpClient');
  }

  let _getConfig = () => ({});
  const getLogger = () => _getConfig().logger || utils.noLogger;

  return {

    configure: function configure(getConfig) {
      _getConfig = getConfig;
    },

    paramsSerializer,

    executeRequest: async function (httpMethod, url, {
      publicEndpoint,
      httpOptions,
      data,
      queryParams,
      cb,
      returnModel,
      extraRequestHttpOptions,
    }) {
      const { accessToken } = publicEndpoint ?
        {} : await accessTokenProvider.get(this);

      return this.invoke(httpMethod, url, {
        accessToken,
        httpOptions,
        data,
        queryParams,
        cb,
        returnModel,
        extraRequestHttpOptions,
      });
    },

    newToken: function (scope) {
      return accessTokenProvider.getNewToken(this, scope);
    },

    invoke: function (httpMethod, url, {
      httpOptions,
      accessToken,
      data,
      queryParams,
      extraRequestHttpOptions,
      cb,
      returnModel,
    }) {
      const clientConfig = _getConfig();
      const requestHttpOptions = Object.assign({}, clientConfig, httpOptions);

      // get default host based on environment if not provided
      requestHttpOptions.host = requestHttpOptions.host || utils.getDefaultHost(accessTokenProvider.environment || requestHttpOptions.mode);

      if (accessToken) {
        requestHttpOptions.Authorization = 'Bearer ' + accessToken;
      }

      const headers = Object.assign(
        requestHttpOptions.Authorization ? {
          Authorization: requestHttpOptions.Authorization,
        } : {},
        this.getHeaders(),
      );

      const options = mergeDeepRight({
        data,
        params: queryParams,
        method: httpMethod,
        baseURL: requestHttpOptions.host,
        url,
        headers: headers,
        timeout: requestHttpOptions.timeout,
        paramsSerializer,
      }, extraRequestHttpOptions || {});
      const fullUrl = `${options.baseURL}${options.url}`;
      getLogger().verbose(`REQUEST - ${options.method} ${fullUrl}`);
      getLogger().debug('REQUEST extra params:', { extra: options });

      const requestPromise = httpClient(options)
        .then(...measureRequest());

      return this.handleApiResponse(requestPromise, {
        requestConfig: {
          method: options.method,
          url: fullUrl,
          params: options.params,
          data: options.data,
        },
        cb,
        returnModel,
      });
    },

    getHeaders: function () {
      return {
        Accept: 'application/vnd.payrailz.api+json; version=v1',
        'Content-Type': 'application/json',
      };
    },

    handleApiResponse: function (promise, {
      requestConfig,
      cb,
      returnModel,
    } = {}) {
      const {
        method,
        url,
        queryParams,
        data,
      } = requestConfig || {};
      return promise
        .catch(err => {
          let error = err;

          const isBusinessError = (statusCode) => statusCode >= 400 && statusCode < 500;
          if (err.response && err.response.data && isBusinessError(err.response.status)) {
            error = err.response.data;
            error.statusCode = err.response.status;
            error.message = pathOr(error.httpStatus, ['error', 'message'], error);
          }

          if (typeof cb === 'function') {
            cb(error, null);
          }
          error.requestConfig = {
            method,
            url,
            queryParams,
            data,
          }
          if (err.response) {
            const { duration } = err;
            const { data: dataFromErrorResponse, status, headers } = err.response;
            getLogger().verbose(`RESPONSE ${status} (${duration}ms) - ${method} ${url} - ${error.message}`);
            getLogger().debug('RESPONSE headers:', { extra: headers });
            getLogger().debug('RESPONSE data:', { extra: dataFromErrorResponse });
          }
          else {
            getLogger().verbose(`RESPONSE TIMEOUT - ${method} ${url} - ${error.message}`);
          }
          throw error;
        })
        .then((res) => {
          const { status, statusText, headers, data: dataFromResponse, duration } = res;

          getLogger().verbose(`RESPONSE ${status} ${statusText} (${duration}ms) - ${method} ${url}`);
          getLogger().debug('RESPONSE headers:', { extra: headers });
          getLogger().debug('RESPONSE data:', { extra: dataFromResponse });

          if (typeof(dataFromResponse) === 'string') {
            getLogger().verbose('RESPONSE api did not returned a JSON response');
            const error = new Error('the server response was not properly encoded as JSON');
            error.status = 'failure';
            error.statusText = error.message;
            error.data = dataFromResponse;
            throw error;
          }
          return res;
        })
        .then(res => {
          const transformDefinition = createTransformDefinition(returnModel);
          const businessData = transformDefinition.extractData(res.data);
          return applyModelTransformation(businessData, transformDefinition);
        })
        .then(processedData => {
          if (typeof cb === 'function') {
            cb(null, processedData);
          }
          return processedData;
        });

    },
    createTransformDefinition,
    transformData,
    applyModelTransformation,

  };
};
